/*! CSS Used from: https://staging.securemobile.net/pub/static/frontend/Codazon/fastest_bigmarket/en_AU/MobileDigital_Click2Buy/all.css ; media=all */
@media all {
  #top-footer-col-4 a {
    width: 140px;
  }
  .page-header .header.links > li {
    display: block !important;
  }
  .page-header .header.links li.greet.welcome {
    display: none !important;
  }
  #topfooter {
    display: none;
  }
  .widget.block.block-static-block {
    display: none;
  }
  header .panel.wrapper {
    display: none;
  }
  body {
    background: #fff !important;
  }
  header .nav-toggle span:after,
  header .nav-toggle span:before,
  header .nav-toggle:before {
    color: #fff;
  }
  header .header-wrapper-content {
    background: #fff;
  }
  .page-header .minicart-wrapper .action.showcart {
    background: 0 0;
    position: absolute;
    top: 11px;
    right: 8px;
  }
  .page-header {
    background-color: #fff !important;
  }
  @media only screen and (max-width: 767px) {
    .minicart-wrapper .action.showcart {
      position: absolute;
      right: 15px;
      z-index: 5;
    }
  }
  body * {
    font-family: Montserrat, sans-serif !important;
  }
  .footer-container-wrapper {
    display: none;
  }
  .page-footer .bottom-footer-wrapper {
    background: 0 0 !important;
    border: none !important;
  }
  .footer-small-link a {
    color: #292929;
    font-size: 10px;
    font-weight: 600;
  }
  .footer-small-link {
    width: 50%;
    float: left;
  }
  #footer-small-links-parent {
    width: 100%;
    height: 20px;
  }
  #footer-powered-by-parent {
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .cart-footer .cart-qty {
    background-color: #0198d8 !important;
  }
  .header-wrapper-left .logo-left {
    margin-left: -15px;
    margin-right: -15px;
    margin-top: -10px;
    height: 55px;
    width: 100%;
  }
  #toggle-nav-parent {
    float: left;
    width: 55px;
    height: 55px;
    background: #0198d8;
  }
  #header-right-part {
    width: auto;
    height: 55px;
    background: #0198d8;
    margin-right: -30px;
    position: relative;
  }
  .minicart-wrapper .action.showcart .counter.qty {
    background: #fff !important;
  }
  .minicart-wrapper .action.showcart .counter.qty .counter-number {
    color: #0198d8 !important;
  }
  .minicart-wrapper .action.showcart:before {
    padding: 7px 10px !important;
    width: 40px !important;
    font-family: Pe-icon-7-stroke !important;
    content: "\e66e" !important;
    font-size: 24px !important;
    display: unset !important;
  }
  .nav-toggle {
    left: 11px !important;
    top: 18px !important;
    display: block !important;
  }
  #merchant-store-name {
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    padding-left: 15px;
    top: 15px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
  }
  #minicart-content-wrapper {
    display: none;
    color: #000;
    position: relative;
  }
  .minicart-wrapper .block-minicart {
    margin: 10px;
    margin-top: 20px;
    z-index: 100 !important;
  }
  .cart-mask {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100%;
    z-index: 50;
    display: none;
  }
  #cart-popup-x {
    font-size: 23px;
    font-weight: 400;
    position: absolute;
    top: -20px;
    right: -12px;
  }
  #cart-footer {
    display: none !important;
  }
  #general-loader {
    display: none;
  }
  #cart-popup-empty {
    text-align: center;
    padding-bottom: 20px;
    font-size: 30px;
    font-weight: 300;
    padding-top: 10px;
  }
  main#maincontent .page.messages {
    display: none;
  }
  #md-popup-mask {
    display: none;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
  }
  #md-popup {
    display: none;
    position: fixed;
    top: 50%;
    z-index: 1002;
    background: #fff;
    width: 300px;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    font-size: 14px;
  }
  #md-popup-image-parent {
    text-align: center;
    margin-bottom: 12px;
  }
  #md-popup-x {
    font-size: 20px;
    font-weight: 700;
    position: absolute;
    top: 3px;
    right: 10px;
  }
  #md-popup-content {
    margin-top: 5px;
  }
  .page-footer .bottom-footer-wrapper {
    padding: 0 !important;
  }
  .loader {
    padding: 300px 0 !important;
  }
  .header.content .col-md-7:first-child,
  .header.content .col-sm-10 {
    width: 100% !important;
  }
  .header.content {
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-top: 10px !important;
  }
  .action.showcart .price-label {
    display: none !important;
  }
  .header.content .col-sm-6.col-md-7 {
    position: unset !important;
  }
  .minicart-wrapper {
    position: unset !important;
  }
  @media (min-width: 768px) {
    .page-header .minicart-wrapper .action.showcart {
      top: 14px;
      right: 8px;
    }
    .nav-toggle {
      top: 10px !important;
    }
  }
  .nav-sections {
    -webkit-transition: left 0.3s;
    -moz-transition: left 0.3s;
    -ms-transition: left 0.3s;
    transition: left 0.3s;
    height: 100%;
    left: -80%;
    left: calc(-1 * (100% - 54px));
    overflow: auto;
    position: fixed;
    top: 0;
    width: 80%;
    width: calc(100% - 54px) !important;
  }
  .nav-sections-item-title {
    background: #f8f8f8 !important;
    border: 0 solid #424242 !important;
    border-width: 0 !important;
    box-sizing: border-box !important;
    float: left !important;
    height: 52px !important;
    padding-top: 18px !important;
    text-align: center !important;
    width: 33.33% !important;
    text-transform: uppercase !important;
  }
  .nav-sections-item-content {
    box-sizing: border-box !important;
    float: right !important;
    margin-left: -100% !important;
    margin-top: 52px !important;
    width: 100% !important;
    text-align: left !important;
    padding: 0 !important;
  }
  .nav-sections-item-content > * {
    display: block !important;
  }
  .nav-sections-item-title {
    display: block !important;
  }
  .navigation {
    background: #bd1004;
    box-sizing: border-box;
  }
  .navigation {
    background: 0 0 !important;
    padding: 0;
  }
  .nav-sections-item-content:after,
  .nav-sections-item-content:before {
    content: "";
    display: table;
  }
  .nav-sections-item-content:after {
    clear: both;
  }
  .nav-sections-item-content:after,
  .nav-sections-item-content:before {
    content: "";
    display: table;
  }
  .nav-sections-item-title .nav-sections-item-switch:visited {
    color: #292929;
    text-decoration: none;
  }
  .nav-sections-item-title .nav-sections-item-switch {
    color: #292929;
    text-decoration: none;
  }
  .navigation .level0 {
    display: block !important;
    border-bottom: 1px solid #424242 !important;
  }
  .navigation li.level0 > .level-top {
    background: 0 0 !important;
  }
  .header-wrapper-bottom {
    background: 0 0 !important;
  }
  @media (min-width: 768px) {
    .footer.content,
    .header.content,
    .navigation,
    .page-header .header.panel,
    .page-main,
    .page-wrapper > .widget {
      max-width: 400px !important;
    }
    .organisations-index-index .page-main {
      max-width: 100% !important;
    }
    .organisations-index-index #maincontent,
    .organisations-index-index .columns {
      padding: 0 !important;
    }
    .organisations-index-index .column.main {
      padding: 0 !important;
    }
  }
  .organisations-index-index #text-section {
    padding-top: 140px;
  }
  .organisations-index-index .columns {
    margin-bottom: 0 !important;
  }
  .organisations-index-index footer.page-footer {
    display: none;
  }
  #desktop-menu-x {
    display: none;
    position: absolute;
    right: 20px;
    top: 5px;
    z-index: 999;
    font-size: 25px;
  }
  #desktop-menu-x {
    display: none !important;
  }
  .page-footer .copyright {
    float: none;
    text-align: center;
    width: 100%;
    display: block;
  }
  #footer-powered-by-parent img {
    height: 90px;
  }
  @media (max-width: 768px) {
    #footer-powered-by-parent img {
      height: 80px;
    }
  }
  #general-mask {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: none;
  }
  #image-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 1001;
    transform: translate(-50%, -50%);
    display: none;
  }
  #image-popup img {
    width: 300px;
  }
  #image-popup-left {
    background: #fff;
    top: 50%;
    left: 0;
    width: 60px;
    height: 60px;
    position: fixed;
    transform: translate(0, -50%);
    border-radius: 35px;
    color: #000;
  }
  #image-popup-left:hover {
    background: #000;
    color: #fff;
    cursor: pointer;
  }
  #image-popup-right {
    background: #fff;
    top: 50%;
    right: 0;
    width: 60px;
    height: 60px;
    position: fixed;
    transform: translate(0, -50%);
    border-radius: 35px;
    color: #000;
  }
  #image-popup-right:hover {
    background: #000;
    color: #fff;
    cursor: pointer;
  }
  #image-popup-left:before {
    content: "\e686";
    font-family: Pe-icon-7-stroke;
    font-size: 48px;
    position: relative;
    top: -3px;
    left: 5px;
  }
  #image-popup-right:before {
    content: "\e684";
    font-family: Pe-icon-7-stroke;
    font-size: 48px;
    position: relative;
    top: -3px;
    left: 7px;
  }
  .page-header .minicart-wrapper .action.showcart .counter.qty {
    top: -3px !important;
    left: auto !important;
    right: -3px !important;
    height: 19px !important;
    line-height: 20px !important;
    color: #fff !important;
    font-size: 11px !important;
    min-width: 20px !important;
  }
  .page-header .minicart-wrapper .action.showcart:before {
    position: relative !important;
    top: 0 !important;
    left: -7px !important;
    font-weight: 700 !important;
    border: none !important;
  }
  @media only screen and (min-width: 768px) {
    .page-header .minicart-wrapper .action.showcart:before {
      position: relative !important;
      top: -10px !important;
      left: 0 !important;
      font-weight: 700 !important;
    }
    .page-header .minicart-wrapper .action.showcart {
      position: absolute !important;
    }
  }
  .action.showcart > .text {
    display: none !important;
  }
  .page-header .minicart-wrapper .action.showcart {
    border: none !important;
  }
  .header-main-menu .nav-sections {
    width: calc(100% - 54px) !important;
  }
  #header-all-img {
    max-height: 62px;
    position: relative;
    top: -18px;
    left: -15px;
  }
  #bottomfooter {
    margin-top: 10px;
  }
  @media (min-width: 768px) {
    .header-wrapper-left .logo-left {
      width: 400px !important;
    }
  }
  .action.primary {
    background: #0198d8 !important;
    border: none !important;
  }
  .action.primary:active,
  .action.primary:focus,
  .action.primary:hover {
    color: #0198d8 !important;
    background: #fff !important;
    border: 1px solid #0198d8 !important;
  }
  header.page-header {
    height: 55px;
  }
  .page-header .header.links > li {
    display: block !important;
  }
  .organisations-index-index header.page-header {
    display: none;
  }
  section.sections.headers.animate {
    background-color: rgba(0, 0, 0, 0.3);
  }
  .sections.headers {
    min-height: 0;
    position: fixed;
    z-index: 100;
    width: 100%;
  }
  header .wrapper .hdr-logo {
    max-width: 180px;
    float: left;
    left: 9%;
    position: relative;
  }
  header .wrapper .main-menu {
    float: right;
    margin-top: 28px;
  }
  footer,
  header,
  main,
  nav,
  section {
    display: block;
  }
  header .wrapper .main-menu #nav-main .nav-primary {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    align-items: center;
  }
  header .wrapper .main-menu #nav-main .nav-primary li {
    display: inline-block;
    float: left;
    position: relative;
    padding: 0 15px;
  }
  header .wrapper .main-menu #nav-main .nav-primary li a.nav_link.animate {
    padding-right: 15px;
  }
  header .wrapper .main-menu #nav-main .nav-primary li a {
    color: #fff;
    font-family: rift-soft, sans-serif !important;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 1.5px;
    line-height: 15px;
    text-transform: uppercase;
    padding: 0 20px;
    display: inline-block;
    position: relative;
    transition: all 0.2s;
    position: relative;
  }
  header .wrapper .main-menu #nav-main .nav-primary li.blue_btn a {
    padding: 12px 20px 11px;
    background: #30b7c6;
    border-radius: 5px;
    color: #fff;
    border: 1px solid #30b7c6;
  }
  header .wrapper .main-menu #nav-main .nav-primary li.gray_btn a {
    background: #d3d5d6;
    border-radius: 5px;
    padding: 12px 20px 11px;
    color: #fff;
    border: 1px solid #d3d5d6;
  }
  header .wrapper {
    padding: 9px 50px;
    display: inline-block;
    width: 100%;
  }
  .sm-container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
  @media (min-width: 576px) {
    .sm-container {
      max-width: 540px;
    }
  }
  @media (min-width: 768px) {
    .sm-container {
      max-width: 720px;
    }
  }
  @media (min-width: 992px) {
    .sm-container {
      max-width: 960px;
    }
  }
  @media (min-width: 1200px) {
    .sm-container {
      max-width: 1140px;
    }
    #top-footer-col-4 img {
      position: relative;
      top: 15px;
    }
  }
  .fa {
    font-family: FontAwesome !important;
  }
  .footer-logo {
    max-height: 94px;
    margin-bottom: 36px;
  }
  .footer-logo-small {
    margin-bottom: 36px;
    margin-top: 15px;
  }
  #top-footer-section {
    margin-top: 63px;
    margin-bottom: 30px;
  }
  .top-footer-col {
    float: left;
  }
  .top-footer-col:last-child {
    float: left;
  }
  .top-footer-text {
    display: inline-block;
  }
  #top-footer-text-1 {
    font-size: 13px;
    margin-top: 19px;
    margin-right: 12px;
  }
  #top-footer-text-1 span {
    color: #3dc2d0;
  }
  #top-footer-text-1 a,
  #top-footer-text-1 span {
    font-family: rift-soft, sans-serif !important;
  }
  #top-footer-text-1 a {
    color: #017496;
    letter-spacing: 0.5px;
  }
  #bottom-footer-section {
    clear: both;
  }
  .country-icon {
    height: 25px;
  }
  #top-footer-text-2 {
    position: relative;
    top: -5px;
  }
  #top-footer-text-2 #legal-heading {
    text-transform: uppercase;
    font-size: 12px;
  }
  #middle-line {
    display: inline-block;
    height: 85px;
    width: 2px;
    background: #a7a6a6;
    position: relative;
    top: 12px;
    margin-right: 8px;
    margin-left: 8px;
  }
  #last-line {
    display: inline-block;
    height: 85px;
    width: 2px;
    background: #a7a6a6;
    position: relative;
    top: 12px;
    margin-right: 8px;
    margin-left: 12px;
  }
  .footer-link a {
    color: #000;
    font-size: 11px;
  }
  #footer-sm-logo {
    margin-right: 15px;
  }
  #top-footer-col-0 {
    width: 0%;
    height: 100px;
  }
  #top-footer-col-1 {
    width: 25%;
  }
  #top-footer-col-2 {
    width: 31%;
  }
  #top-footer-col-22 {
    width: 11%;
  }
  #top-footer-col-3 {
    width: 20%;
  }
  #top-footer-col-4 {
    width: 12%;
  }
  #last-line {
    display: none;
  }
  @media (max-width: 1199px) and (min-width: 992px) {
    #top-footer-col-0 {
      width: 0%;
    }
    #top-footer-col-1 {
      width: 24%;
    }
    #top-footer-col-2 {
      width: 34%;
    }
    #top-footer-col-22 {
      width: 11%;
    }
    #top-footer-col-3 {
      width: 21%;
    }
    #top-footer-col-4 {
      width: 10%;
    }
    #top-footer-col-4 a {
      width: 100px;
    }
    .footer-logo {
      max-height: 85px;
    }
    #top-footer-col-4 img {
      position: relative;
      top: 15px;
    }
  }
  @media (min-width: 1675px) {
    #top-footer-col-0 {
      width: 0%;
    }
  }
  #footer-copyright {
    text-align: center;
    color: #919191;
    font-size: 11px;
    padding-top: 25px;
    padding-bottom: 25px;
  }
  #bottom-footer-section {
    background-color: #f2f2f2;
  }
  .header-line {
    color: #fff;
    font-size: 20px;
    position: relative;
    top: -7px;
  }
  #top-footer-col-22 .footer-logo {
    margin-top: 33px;
  }
  #top-footer-col-22 a:first-child .footer-logo {
    margin-left: 5px;
  }
  #top-footer-col-22 a:last-child .footer-logo {
    margin-right: 5px;
  }
  .c-hamburger {
    display: none;
    position: absolute;
    overflow: hidden;
    margin: 0;
    padding: 0;
    width: 31px;
    height: 21px;
    font-size: 0;
    text-indent: -9999px;
    appearance: none;
    box-shadow: none;
    border-radius: none;
    border: none;
    cursor: pointer;
    background: 0 0 !important;
    right: 37px;
    top: 44px;
  }
  .c-hamburger:focus {
    outline: 0;
  }
  .c-hamburger span {
    display: block;
    position: absolute;
    top: 18px;
    left: 0;
    right: 0;
    height: 3px;
    background: #fff;
    border-radius: 10px;
  }
  .c-hamburger span::after,
  .c-hamburger span::before {
    position: absolute;
    display: block;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: #fff;
    border-radius: 10px;
    content: "";
  }
  .c-hamburger span::before {
    top: -8px;
    width: 60%;
  }
  .c-hamburger span::after {
    bottom: 16px;
  }
  .c-hamburger--htx span {
    transition: background 0s 0.3s;
  }
  .c-hamburger--htx span::after,
  .c-hamburger--htx span::before {
    transition-duration: 0.3s, 0.3s;
    transition-delay: 0.3s, 0s;
  }
  .c-hamburger--htx span::before {
    transition-property: top, transform;
  }
  .c-hamburger--htx span::after {
    transition-property: bottom, transform;
  }
  @media screen and (max-width: 1440px) {
    header .wrapper .hdr-logo {
      left: 0;
    }
    .c-hamburger {
      display: block;
    }
    header .wrapper .main-menu #nav-main {
      padding-bottom: 30px;
      z-index: 998;
      opacity: 1;
      top: 73px;
    }
    header .wrapper .main-menu #nav-main {
      display: block;
      position: fixed;
      width: 100%;
      left: 0;
      top: -100%;
      background: #fff;
      height: 100%;
      z-index: 998;
      overflow-x: hidden;
      overflow-y: auto;
      opacity: 1;
      -webkit-transition: all 0.5s ease-in-out;
      -moz-transition: all 0.5s ease-in-out;
      -o-transition: all 0.5s ease-in-out;
      -ms-transition: all 0.5s ease-in-out;
      transition: all 0.5s ease-in-out;
    }
    header .wrapper .main-menu #nav-main .nav-primary {
      display: block;
    }
    header .wrapper .main-menu #nav-main .nav-primary li {
      float: left;
      width: 100%;
      margin-top: 40px;
      padding: 0 0;
      position: relative;
    }
    header .wrapper .main-menu #nav-main .nav-primary li {
      padding: 0;
    }
    header .wrapper .main-menu #nav-main .nav-primary li {
      padding: 0;
    }
    header .wrapper .main-menu #nav-main .nav-primary li {
      padding: 0 5px;
    }
    header
      .wrapper
      .main-menu
      #nav-main
      .nav-primary
      li.menu-item-has-children
      a {
      float: left;
      width: 100%;
    }
    header .wrapper .main-menu #nav-main .nav-primary li a {
      padding: 0 20px;
    }
    .headers .wrapper .main-menu #nav-main {
      display: block;
      position: fixed;
      width: 100%;
      left: 0;
      top: -100%;
      background: #fff;
      height: 100%;
      z-index: 998;
      overflow-x: hidden;
      overflow-y: auto;
      opacity: 1;
      -webkit-transition: all 0.5s ease-in-out;
      -moz-transition: all 0.5s ease-in-out;
      -o-transition: all 0.5s ease-in-out;
      -ms-transition: all 0.5s ease-in-out;
      transition: all 0.5s ease-in-out;
    }
    .headers .wrapper .main-menu #nav-main .nav-primary {
      display: block;
    }
    .headers .wrapper .main-menu #nav-main .nav-primary li {
      float: left;
      width: 100%;
      margin-top: 40px;
      padding: 0 0;
      position: relative;
    }
    .headers
      .wrapper
      .main-menu
      #nav-main
      .nav-primary
      li.menu-item-has-children
      a {
      float: left;
      width: 100%;
    }
    .headers .wrapper .main-menu #nav-main .nav-primary li.blue_btn,
    .headers .wrapper .main-menu #nav-main .nav-primary li.gray_btn {
      padding: 0 20px;
    }
    .headers .wrapper .main-menu #nav-main .nav-primary li.blue_btn a,
    .headers .wrapper .main-menu #nav-main .nav-primary li.gray_btn a {
      float: left;
      width: 100%;
      text-align: center;
      padding: 11px 20px;
    }
    .headers .wrapper .main-menu #nav-main .nav-primary li a {
      padding: 0 20px;
    }
    .headers .wrapper .main-menu #nav-main .nav-primary li a:after {
      display: none;
    }
    .headers .wrapper .main-menu #nav-main .nav-primary li.blue_btn {
      margin-top: 20px;
    }
    .headers .wrapper .main-menu .c-hamburger {
      display: block;
    }
    header .wrapper .main-menu #nav-main .nav-primary li a {
      color: #000;
    }
    .header-line {
      display: none;
    }
    .headers .wrapper .main-menu #nav-main .nav-primary li.blue_btn a,
    .headers .wrapper .main-menu #nav-main .nav-primary li.gray_btn a {
      max-width: 280px;
    }
  }
  @media screen and (max-width: 1000px) {
    #top-footer-col-0 {
      display: none;
    }
    #footer-sm-logo {
      max-height: 100px;
    }
    .top-footer-col {
      text-align: center;
    }
    #top-footer-col-1,
    #top-footer-col-2,
    #top-footer-col-22,
    #top-footer-col-3,
    #top-footer-col-4 {
      width: 100%;
    }
    #top-footer-col-22 {
      margin-top: 25px;
      margin-bottom: 25px;
      position: relative;
      left: -16px;
    }
    #top-footer-col-22 .footer-logo {
      margin-top: 0;
    }
    #top-footer-text-1,
    #top-footer-text-2 {
      text-align: left;
    }
    #top-footer-col-2 {
      position: relative;
      left: -56px;
    }
    .footer-logo-small {
      margin-bottom: 0;
      margin-top: 0;
    }
    #last-line {
      display: none;
    }
    .footer-logo {
      margin-bottom: 0;
    }
    #top-footer-col-3 {
      margin-top: 25px;
      margin-bottom: 25px;
      position: relative;
      left: -13px;
    }
    #top-footer-col-4 {
      position: relative;
      left: -16px;
    }
  }
  @media screen and (max-width: 580px) {
    #middle-line {
      display: none;
    }
    .top-footer-text {
      display: block;
    }
    .top-footer-text > div {
      text-align: left;
    }
    #top-footer-text-1,
    #top-footer-text-2 {
      margin-left: auto;
      margin-right: auto;
    }
    #top-footer-col-2 {
      left: 0;
    }
    #top-footer-text-1 {
      width: 180px;
    }
    #top-footer-text-2 {
      margin-top: 25px;
      width: 120px;
    }
  }
  .content-heading {
    font-family: rift-soft, sans-serif !important;
  }
  .content-main-heading {
    font-size: 32px;
  }
  .content-minor-heading {
    font-size: 22px;
  }
  .content-mid-heading {
    font-size: 28px;
  }
  .content-center-img {
    text-align: center;
  }
  #text-section p {
    font-size: 16px;
  }
  #text-section li {
    font-size: 16px;
  }
  .header-active {
    color: #3dc2d0 !important;
  }
  ul.thumbs-up div {
    position: relative;
    top: -8px;
  }
  #text-section ul.thumbs-up li {
    margin-bottom: 0;
  }
  @media (max-width: 768px) {
    .organisations-index-index .columns {
      padding: 0 !important;
    }
    .organisations-index-index .page-main {
      padding-left: 0 !important;
      padding-right: 0 !important;
      padding-top: 0 !important;
    }
  }
  #top-footer-col-4 img {
    max-height: 75px;
  }
  @media (min-width: 1200px) {
    #top-footer-col-4 img {
      position: relative;
      top: 15px;
    }
  }
  @media (max-width: 1560px) {
    header .wrapper .hdr-logo {
      left: 0;
    }
  }
  #mobile-code-message {
    position: fixed;
    top: 50%;
    left: 50%;
    color: #000;
    z-index: 99999;
    transform: translate(-50%, -50px);
    font-size: 30px;
    width: 590px;
    text-align: center;
    border-radius: 22px;
    padding: 21px;
    display: none;
  }
  #mobile-code-loader {
    background: rgba(255, 255, 255, 0.7) !important;
  }
  .grecaptcha-badge {
    bottom: 21px !important;
    right: 50% !important;
    transform: translate(50%, 0);
  }
  #mobile-page-container2 {
    height: 195px;
  }
  #mobile-code-loader {
    display: none;
  }
  @media (max-width: 620px) {
    #mobile-code-message {
      width: 90%;
      font-size: 24px;
    }
  }
  #new-sm-menu {
    display: block !important;
    position: fixed;
    background: #fff;
    z-index: 1010;
    top: 0;
    left: -100%;
    -webkit-transition: left 0.3s;
    -moz-transition: left 0.3s;
    -ms-transition: left 0.3s;
    -o-transition: left 0.3s;
    transition: left 0.3s;
    width: 100%;
    height: 100%;
    overflow: scroll;
  }
  #new-sm-menu-close,
  #new-sm-menu-close:visited {
    position: absolute;
    right: 0;
    font-size: 24px;
    padding-top: 3px;
    padding-bottom: 3px;
    width: 40px;
    text-align: center;
    color: #000 !important;
    text-decoration: none;
    cursor: pointer;
  }
  #new-sm-menu-close:active,
  #new-sm-menu-close:hover {
    color: #fff !important;
    background: #000 !important;
  }
  #new-sm-menu .menu-nav {
    width: calc(50% - 20px);
    float: left;
    text-align: center;
    font-size: 18px;
    padding-top: 7px;
    padding-bottom: 7px;
    cursor: pointer;
    background: 0 0;
    color: #000;
  }
  #new-sm-menu .menu-nav.sm-nav-active {
    background: #0296d5;
    color: #fff;
  }
  .categories-header {
    text-align: center;
    font-size: 18px;
    margin-top: 10px;
  }
  #categories-level-2 {
    display: none;
  }
  #categories-level-3 {
    display: none;
  }
  #new-sm-menu #account-nav {
    display: none;
  }
  .new-sm-nav-item {
    font-size: 18px;
    margin: 15px;
    position: relative;
  }
  #new-sm-menu a,
  #new-sm-menu a:visited {
    text-decoration: none;
    cursor: pointer;
    color: #000;
  }
  #new-sm-menu a:active,
  #new-sm-menu a:hover {
    color: #0296d5;
  }
  #new-sm-menu-buffer {
    width: 100%;
    height: 40px;
  }
  #new-sm-menu-nav-parent .sm-nav-active {
    cursor: default;
  }
  #new-sm-menu-mask {
    display: none;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1009;
  }
  @media (min-width: 768px) {
    #new-sm-menu {
      width: 400px;
      height: 600px;
      top: 50%;
      transform: translate(-50%, -50%);
      -webkit-transition: left 1s;
      -moz-transition: left 1s;
      -ms-transition: left 1s;
      -o-transition: left 1s;
      transition: left 1s;
    }
  }
} /*! CSS Used from: https://staging.securemobile.net/pub/static/frontend/Codazon/fastest_bigmarket/en_AU/MobileDigital_Click2Buy/mobile-icon.css ; media=all */
@media all {
  #mobile-page-confirm-container2 {
    display: none;
  }
  #mobile-code-submit,
  #mobile-number-submit {
    margin-top: 0;
  }
  #mobile-code-submit,
  #mobile-number-submit {
    color: #fff !important;
    background: #0198d8 !important;
    font-size: 14px;
    border: none !important;
    border-radius: 30px;
    height: 40px;
  }
  #mobile-code-submit:hover,
  #mobile-number-submit:hover {
    color: #0198d8 !important;
    background-color: #fff !important;
    border: 1px solid #0198d8 !important;
  }
  #mobile-code-submit div,
  #mobile-number-submit div {
    position: relative;
    top: -4px;
  }
  #mobile-code,
  #mobile-number {
    margin-top: 14px;
    font-size: 16px;
  }
  #exampleModal {
    opacity: 1;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  #exampleModal .modal-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none;
  }
  #exampleModal .modal-content {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0;
  }
  #exampleModal .modal-body {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1rem;
    position: relative;
    padding: 15px;
    width: 100%;
    padding-bottom: 50px !important;
    padding-top: 50px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  #exampleModal .close.position-absolute {
    cursor: pointer;
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: absolute !important;
    float: right;
    font-weight: 700;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    top: 6px;
    right: 8px;
  }
  .contactForm {
    width: 100%;
  }
  #exampleModal .contactPopup {
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    position: fixed;
    width: 450px;
  }
  #exampleModal {
    display: none;
  }
  #mobile-page-success-container2 {
    display: none;
  }
  @media (max-width: 560px) {
    #exampleModal .contactPopup {
      width: 90%;
    }
  }
} /*! CSS Used from: https://staging.securemobile.net/pub/static/frontend/Codazon/fastest_bigmarket/en_AU/MobileDigital_Click2Buy/style.css ; media=all */
@media all {
  body {
    -ms-overflow-style: scrollbar;
    min-width: 320px;
  }
  @media (min-width: 576px) {
    body {
      font-size: 16px;
    }
  }
  @media (min-width: 768px) {
    body {
      line-height: 28px;
    }
  }
  @media (min-width: 768px) {
    h2 {
      font-size: 35px;
    }
  }
  @media (min-width: 992px) {
    h2 {
      font-size: 40px;
    }
  }
  @media (min-width: 576px) {
    h3 {
      font-size: 28px;
    }
  }
  @media (min-width: 992px) {
    h3 {
      font-size: 35px;
    }
  }
  @media (min-width: 576px) {
    h4 {
      font-size: 26px;
    }
  }
  @media (min-width: 768px) {
    h4 {
      font-size: 30px;
    }
  }
  .fwSemibold {
    font-weight: 600;
  }
  .btn:active,
  .btn:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  #msgSubmit {
    display: none;
  }
  .contactPopup {
    max-width: 460px;
  }
  .contactPopup .h6 {
    font-size: 24px;
    line-height: 28px;
  }
  .contactPopup .close {
    top: 0;
    right: 0;
    font-size: 22px;
    line-height: 22px;
    padding: 16px 18px;
    opacity: 1;
  }
  .contactPopup .close span {
    -webkit-transition: color 0.35s ease;
    -o-transition: color 0.35s ease;
    transition: color 0.35s ease;
  }
  .contactForm p {
    margin-bottom: 32px;
  }
  @media (min-width: 576px) {
    .contactForm p {
      margin-bottom: 36px;
    }
  }
  @media (min-width: 768px) {
    .contactForm p {
      margin-bottom: 54px;
    }
  }
  .contactForm .form-control {
    margin-bottom: 20px;
  }
} /*! CSS Used from: https://staging.securemobile.net/pub/static/frontend/Codazon/fastest_bigmarket/en_AU/css/styles-m.css ; media=all */
@media all {
  body {
    margin: 0;
    padding: 0;
  }
  footer,
  header,
  main,
  nav,
  section {
    display: block;
  }
  nav ul {
    list-style: none none;
  }
  img {
    border: 0;
  }
  img {
    max-height: 100%;
    max-width: 100%;
  }
  body {
    color: #333;
    font-family: Poppins, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.42857143;
    font-size: 1.4rem;
  }
  p {
    margin-top: 0;
    margin-bottom: 1rem;
  }
  strong {
    font-weight: 700;
  }
  small {
    font-size: 12px;
  }
  h2 {
    font-weight: 300;
    line-height: 1.1;
    font-size: 2.6rem;
    margin-top: 2.5rem;
    margin-bottom: 2rem;
  }
  h3 {
    font-weight: 600;
    line-height: 1.1;
    font-size: 1.8rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
  h4 {
    font-weight: 700;
    line-height: 1.1;
    font-size: 1.4rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  h6 {
    font-weight: 700;
    line-height: 1.1;
    font-size: 1rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  a {
    color: #1979c3;
    text-decoration: none;
  }
  a:visited {
    color: #1979c3;
    text-decoration: none;
  }
  a:hover {
    color: #006bb4;
    text-decoration: underline;
  }
  a:active {
    color: #ff5501;
    text-decoration: underline;
  }
  ul {
    margin-top: 0;
    margin-bottom: 2.5rem;
  }
  ul > li {
    margin-top: 0;
    margin-bottom: 1rem;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    max-width: 100%;
  }
  table th {
    text-align: left;
  }
  table > tbody > tr > td,
  table > tbody > tr > th {
    vertical-align: top;
  }
  table > tbody > tr > td,
  table > tbody > tr > th {
    padding: 11px 10px;
  }
  button {
    background-image: none;
    background: #006588;
    border: 1px solid #006588;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: Poppins, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 700;
    margin: 0;
    padding: 7px 15px;
    font-size: 1.4rem;
    line-height: 1.6rem;
    box-sizing: border-box;
    vertical-align: middle;
  }
  button:active,
  button:focus {
    background: #fff;
    border: 1px solid #006588;
    color: #006588;
  }
  button:hover {
    background: #fff;
    border: 1px solid #006588;
    color: #006588;
  }
  button::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
  input[type="email"],
  input[type="text"] {
    background: #fff;
    background-clip: padding-box;
    border: 1px solid #c2c2c2;
    border-radius: 1px;
    font-family: Poppins, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    height: 32px;
    line-height: 1.42857143;
    padding: 0 9px;
    vertical-align: baseline;
    width: 100%;
    box-sizing: border-box;
  }
  input[type="email"]:disabled,
  input[type="text"]:disabled {
    opacity: 0.5;
  }
  input[type="email"]::-moz-placeholder,
  input[type="text"]::-moz-placeholder {
    color: #c2c2c2;
  }
  input[type="email"]::-webkit-input-placeholder,
  input[type="text"]::-webkit-input-placeholder {
    color: #c2c2c2;
  }
  input[type="email"]:-ms-input-placeholder,
  input[type="text"]:-ms-input-placeholder {
    color: #c2c2c2;
  }
  textarea {
    background: #fff;
    background-clip: padding-box;
    border: 1px solid #c2c2c2;
    border-radius: 1px;
    font-family: Poppins, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    height: auto;
    line-height: 1.42857143;
    margin: 0;
    padding: 10px;
    vertical-align: baseline;
    width: 100%;
    box-sizing: border-box;
    resize: vertical;
  }
  textarea:disabled {
    opacity: 0.5;
  }
  textarea::-moz-placeholder {
    color: #c2c2c2;
  }
  textarea::-webkit-input-placeholder {
    color: #c2c2c2;
  }
  textarea:-ms-input-placeholder {
    color: #c2c2c2;
  }
  input::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
  form {
    -webkit-tap-highlight-color: transparent;
  }
  :focus {
    box-shadow: none;
    outline: 0;
  }
  input:not([disabled]):focus,
  textarea:not([disabled]):focus {
    box-shadow: 0 0 3px 1px #68a8e0;
  }
  .block.newsletter .label,
  .minicart-wrapper .action.showcart .counter-label,
  .minicart-wrapper .action.showcart .text {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  .header.content:after,
  .header.content:before {
    content: "";
    display: table;
  }
  .header.content:after {
    clear: both;
  }
  .columns .column.main {
    box-sizing: border-box;
  }
  .items {
    margin: 0;
    padding: 0;
    list-style: none none;
  }
  body {
    color: #282828;
    font: 400 12px/1.35 Poppins, Helvetica Neue, Verdana, Arial, sans-serif;
  }
  body input[type="email"],
  body input[type="text"] {
    height: 40px;
    color: #282828;
    border-color: #dbdbdb;
    background-color: #fff;
    font: 400 12px/1.35 Poppins, Helvetica Neue, Verdana, Arial, sans-serif;
  }
  body textarea {
    color: #282828;
    border-color: #dbdbdb;
    background-color: #fff;
    font: 400 12px/1.35 Poppins, Helvetica Neue, Verdana, Arial, sans-serif;
  }
  body input:not([disabled]):focus,
  body textarea:not([disabled]):focus {
    box-shadow: 0 1px 3px #e1e1e1;
  }
  h2 {
    font: 500 20px/1.35 Poppins, Helvetica Neue, Verdana, Arial, sans-serif;
    margin: 0 0 20px;
  }
  h3 {
    font: 500 18px/1.35 Poppins, Helvetica Neue, Verdana, Arial, sans-serif;
    margin: 0 0 20px;
  }
  h4 {
    font: 400 16px/1.35 Poppins, Helvetica Neue, Verdana, Arial, sans-serif;
    margin: 0 0 10px;
  }
  .h5 {
    font: 400 14px/1.35 Poppins, Helvetica Neue, Verdana, Arial, sans-serif;
    margin: 0 0 10px;
  }
  .h6,
  h6 {
    font: 400 11px/1.35 Poppins, Helvetica Neue, Verdana, Arial, sans-serif;
    margin: 0 0 10px;
  }
  a {
    color: #282828;
  }
  a:hover {
    color: #006588;
  }
  .text-center {
    text-align: center;
  }
  .text-uppercase {
    text-transform: uppercase;
  }
  .header a {
    color: #fff;
  }
  .header a:hover {
    color: #006588;
    text-decoration: none;
  }
  body {
    overflow-x: hidden;
    /*background: #f8f8f8 url(https://staging.securemobile.net/pub/static/frontend/Codazon/fastest_bigmarket/en_AU/images/transparent.png) 0 0 no-repeat;*/
  }
  .page-header {
    /*background: #3cc2d0 url(https://staging.securemobile.net/pub/static/frontend/Codazon/fastest_bigmarket/en_AU/images/transparent.png) 0 0 no-repeat fixed;*/
  }
  .page-footer {
    /*background: #fff url(https://staging.securemobile.net/pub/static/frontend/Codazon/fastest_bigmarket/en_AU/images/transparent.png) 0 0 no-repeat;*/
  }
  .page-main {
    padding-top: 20px;
  }
  .columns {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    box-sizing: border-box;
  }
  .columns:after {
    clear: both;
    content: " ";
    display: block;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }
  .columns .column.main {
    -webkit-flex-grow: 1;
    flex-grow: 1;
    -webkit-flex-basis: 100%;
    flex-basis: 100%;
    padding-bottom: 40px;
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
  }
  table > caption {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  table th {
    color: #282828;
    font: 400 14px/1.35 Poppins, Helvetica Neue, Verdana, Arial, sans-serif;
    text-transform: uppercase;
  }
  .panel.header .links {
    display: none;
  }
  .nav-toggle {
    cursor: pointer;
    display: block;
    font-size: 0;
    left: 15px;
    position: absolute;
    top: 23px;
    z-index: 14;
    width: 26px;
    height: 18px;
  }
  .nav-toggle:hover {
    color: #006588;
  }
  .nav-toggle:before {
    position: absolute;
    left: 0;
    top: 8px;
    width: 16px;
    height: 2px;
    background: #fff;
    content: "";
  }
  .nav-toggle span:after,
  .nav-toggle span:before {
    position: absolute;
    left: 0;
    width: 26px;
    height: 2px;
    background: #fff;
    content: "";
    -moz-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    -moz-backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  .nav-toggle span:after {
    bottom: 0;
  }
  .loading-mask {
    bottom: 0;
    left: 0;
    margin: auto;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 100;
    background: rgba(255, 255, 255, 0.5);
  }
  .loading-mask .loader > img {
    bottom: 0;
    left: 0;
    margin: auto;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 100;
  }
  body > .loading-mask {
    z-index: 9999;
  }
  button {
    border-radius: 0;
    margin-bottom: 5px;
  }
  body:not(._keyfocus) button:focus {
    box-shadow: none;
  }
  .action.primary {
    background-image: none;
    background: #006588;
    border: 1px solid #006588;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: Poppins, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 700;
    padding: 7px 15px;
    font-size: 1.4rem;
    box-sizing: border-box;
    vertical-align: middle;
    font: 500 14px/1.35 Poppins, Helvetica Neue, Verdana, Arial, sans-serif;
    vertical-align: top;
    margin-bottom: 5px;
  }
  .action.primary:active,
  .action.primary:focus {
    background: #fff;
    border: 1px solid #006588;
    color: #006588;
  }
  .action.primary:hover {
    background: #fff;
    border: 1px solid #006588;
    color: #006588;
  }
  button {
    text-transform: uppercase;
    -moz-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    -moz-backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  button {
    color: #fff;
    background-color: #006588;
    border-color: #006588;
    font: 500 14px/1.35 Poppins, Helvetica Neue, Verdana, Arial, sans-serif;
  }
  button:hover {
    color: #006588;
    background-color: #fff;
    border-color: #006588;
  }
  button.primary {
    color: #fff;
    background-color: #006588;
    border-color: #006588;
  }
  button.primary:hover {
    color: #fff;
    background-color: #006588;
    border-color: #006588;
  }
  .loader {
    position: relative;
    text-align: center;
    padding: 100px 0;
  }
  .loader img {
    display: none;
  }
  .loader:after,
  .loader:before {
    position: absolute;
    content: "";
    border: 2px solid #dbdbdb;
    width: 31px;
    height: 31px;
  }
  .loader:after {
    animation: cssload-spinner1 2.88s linear infinite;
    -o-animation: cssload-spinner1 2.88s linear infinite;
    -ms-animation: cssload-spinner1 2.88s linear infinite;
    -webkit-animation: cssload-spinner1 2.88s linear infinite;
    -moz-animation: cssload-spinner1 2.88s linear infinite;
  }
  .loader:before {
    width: 43px;
    height: 43px;
    margin-left: -6.5px;
    margin-top: -6.5px;
    animation: cssload-spinner2 2.88s linear infinite;
    -o-animation: cssload-spinner2 2.88s linear infinite;
    -ms-animation: cssload-spinner2 2.88s linear infinite;
    -webkit-animation: cssload-spinner2 2.88s linear infinite;
    -moz-animation: cssload-spinner2 2.88s linear infinite;
  }
  @media (max-width: 767px) {
    .hidden-xs {
      display: none !important;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .hidden-sm {
      display: none !important;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .hidden-md {
      display: none !important;
    }
  }
  .pull-right {
    float: right !important;
  }
  .img-responsive {
    display: block;
    max-width: 100%;
    height: auto;
  }
  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  :after,
  :before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .row {
    margin-left: -10px;
    margin-right: -10px;
  }
  .row:after,
  .row:before {
    content: " ";
    display: table;
  }
  .row:after {
    clear: both;
  }
  .row {
    margin-left: -10px;
    margin-right: -10px;
  }
  .row:after,
  .row:before {
    content: " ";
    display: table;
  }
  .row:after {
    clear: both;
  }
  .col-md-14,
  .col-md-4,
  .col-md-7,
  .col-sm-10,
  .col-sm-12,
  .col-sm-14,
  .col-sm-4,
  .col-sm-6 {
    position: relative;
    min-height: 1px;
    padding-left: 10px;
    padding-right: 10px;
  }
  @media (min-width: 768px) {
    .col-sm-10,
    .col-sm-12,
    .col-sm-14,
    .col-sm-4,
    .col-sm-6 {
      float: left;
    }
    .col-sm-4 {
      width: 16.66667%;
    }
    .col-sm-6 {
      width: 25%;
    }
    .col-sm-10 {
      width: 41.66667%;
    }
    .col-sm-12 {
      width: 50%;
    }
    .col-sm-14 {
      width: 58.33333%;
    }
  }
  @media (min-width: 992px) {
    .col-md-14,
    .col-md-4,
    .col-md-7 {
      float: left;
    }
    .col-md-4 {
      width: 16.66667%;
    }
    .col-md-7 {
      width: 29.16667%;
    }
    .col-md-14 {
      width: 58.33333%;
    }
  }
  .header-wrapper-bottom {
    background-color: #3cc2d0;
  }
  .header-wrapper-bottom .header.content {
    padding-top: 0;
    padding-bottom: 0;
  }
  .left-navigation {
    background-color: transparent;
  }
  .left-navigation .navigation {
    display: block !important;
    background-color: transparent;
  }
  .left-navigation .block {
    margin-bottom: 0 !important;
  }
  .left-navigation .navigation .menu-title {
    padding: 10px 20px;
    display: block;
    color: #fff;
    background-color: #3cc2d0;
    border-color: transparent;
    font: 400 16px/1.35 Poppins, Helvetica Neue, Verdana, Arial, sans-serif;
    cursor: pointer;
  }
  .left-navigation .navigation .menu-title:after {
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\f0c9";
    font-size: 18px;
    float: right;
  }
  .header-main-menu .nav-sections {
    float: left;
    clear: both;
  }
  .cdz-main-menu ul {
    margin: 0;
    padding: 0;
    list-style: none none;
  }
  .cart-footer .cart-qty {
    color: #fff;
    background-color: #006588;
    border-color: #006588;
    font: 400 11px/1.35 Poppins, Helvetica Neue, Verdana, Arial, sans-serif;
  }
  .page-header .header.panel > .header.links > li {
    margin: 0 0 0 20px;
  }
  .page-header .header.links {
    margin: 0;
    padding: 0;
    list-style: none none;
  }
  .page-header .header.links > li {
    display: inline-block;
    vertical-align: top;
  }
  .page-header .header.links li > a {
    position: relative;
  }
  .page-header .header.links li > a:before {
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\f007";
    font-size: 12px;
    margin-right: 5px;
  }
  .page-header .header.links li.greet.welcome {
    display: none;
  }
  .page-header .header.links li.wishlist > a:before {
    content: "\f004";
  }
  .page-header .minicart-wrapper {
    margin-left: 10px;
  }
  .page-header .minicart-wrapper .action.showcart {
    -moz-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    -moz-backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    position: relative;
    text-transform: uppercase;
    text-align: center;
    padding-left: 75px;
  }
  .page-header .minicart-wrapper .action.showcart:before {
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\e66e";
    font-size: 36px;
    font-family: Pe-icon-7-stroke;
    speak: none;
    text-align: center;
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 53px;
    height: 53px;
    line-height: 53px;
    background: 0 0;
    color: #fff;
    border: 2px solid #fff;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
  }
  .page-header .minicart-wrapper .action.showcart .price-label {
    padding: 0;
    min-height: 0;
    display: block;
    clear: both;
  }
  .page-header .minicart-wrapper .action.showcart .text {
    position: relative;
    font-size: 110%;
    padding-top: 7px;
    display: inline-block;
    width: auto;
    height: auto;
    margin: 0;
    vertical-align: top;
  }
  .page-header .minicart-wrapper .action.showcart .counter.qty {
    top: -5px;
    left: 35px;
    height: 22px;
    line-height: 26px;
    background: #fff;
    color: #000;
    font-size: 13px;
    min-width: 22px;
  }
  .page-footer {
    background-color: #fff;
    color: #777;
  }
  .page-footer li,
  .page-footer ul {
    margin: 0;
    padding: 0;
    list-style: none none;
  }
  .page-footer .top-footer-wrapper {
    background-color: #006588;
  }
  .page-footer .footer-container-wrapper {
    padding-top: 50px;
    padding-bottom: 30px;
  }
  .page-footer .footer-content-top {
    padding-top: 30px;
    padding-bottom: 20px;
  }
  .page-footer .footer-content-top .block-static-block.widget {
    margin-bottom: 0;
  }
  .page-footer .block.newsletter {
    margin-bottom: 0;
  }
  .page-footer .block.newsletter .field .control {
    margin-right: 10px;
  }
  .page-footer .block.newsletter input {
    border-width: 1px;
    border-color: #fff;
    background: 0 0;
  }
  .page-footer .block.newsletter .action.subscribe {
    border-width: 1px;
    color: #fff;
    background-color: transparent;
    border-color: #fff;
  }
  .page-footer .block.newsletter .action.subscribe:hover {
    color: #006588;
    background-color: #fff;
    border-color: #006588;
  }
  .page-footer a {
    color: #777;
  }
  .page-footer a:hover {
    color: #006588;
  }
  .page-footer p.h5 {
    color: #3a3a3a;
    font-weight: 600;
    margin-bottom: 25px;
    text-transform: uppercase;
  }
  .page-footer .footer-link {
    margin-top: -10px;
  }
  .page-footer .footer-link li {
    padding: 10px 0;
    border-bottom: 1px solid #e8e8e8;
  }
  .page-footer .footer-link li a {
    display: block;
    -moz-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    -moz-backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  .page-footer .footer-link li.last {
    border-bottom: none;
  }
  .page-footer .bottom-footer-wrapper {
    background: #e8e8e8;
    padding: 30px 0;
    overflow: hidden;
    border-top: 1px solid #e8e8e8;
  }
  .page-footer .bottom-footer-wrapper .block-static-block.widget {
    margin-bottom: 0;
  }
  .page-footer .copyright {
    font: 400 14px/1.35 Poppins, Helvetica Neue, Verdana, Arial, sans-serif;
    float: left;
  }
  .page-footer .footer-custom-block {
    float: right;
  }
  .page-layout-1column .columns {
    margin-bottom: 40px;
    background-color: #fff;
    padding: 20px 10px;
    box-shadow: 0 0 1px #8c8686;
    -moz-box-shadow: 0 0 1px #8c8686;
    -webkit-box-shadow: 0 0 1px #8c8686;
    -o-box-shadow: 0 0 1px #8c8686;
    -ms-box-shadow: 0 0 1px #8c8686;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    margin-left: 0;
    margin-right: 0;
  }
  .page-layout-1column .columns .column.main {
    padding-bottom: 0;
  }
  .header-bottom-right .block-static-block.widget {
    margin-bottom: 0;
  }
  .header-call-us {
    color: #fff;
    padding: 20px 0;
    font: 400 14px/1.35 Poppins, Helvetica Neue, Verdana, Arial, sans-serif;
  }
  .header-call-us:before {
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\f095";
    font-size: 16px;
    padding: 0 5px;
  }
  .float-bar {
    position: fixed;
    -moz-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    -moz-backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    z-index: 2;
    width: 80px;
    border: 1px solid #dbdbdb;
    background: #fff;
  }
  .float-bar .item {
    text-align: center;
    padding: 10px 7px;
    margin: 0;
    font-size: 11px;
    border-bottom: 1px solid #dbdbdb;
  }
  .float-bar .item.last {
    border-bottom: 0;
  }
  .float-bar .item a {
    color: #282828;
    text-decoration: none;
  }
  .float-bar .item a:visited {
    color: #282828;
    text-decoration: none;
  }
  .float-bar .item a:hover {
    color: #006588;
    text-decoration: none;
  }
  .float-bar .item a:active {
    color: #006588;
    text-decoration: none;
  }
  .float-bar .item a span {
    display: block;
  }
  .float-bar .item a .fa {
    margin-bottom: 5px;
    color: #006588;
  }
  .float-bar .item a .fa:before {
    font-size: 18px;
  }
  .left-navigation .navigation .menu-title {
    text-transform: uppercase;
    background: #006588;
  }
  @media (min-width: 768px) and (max-width: 1200px) {
    .page-header .header-wrapper-bottom .header.content {
      padding: 0 10px;
    }
  }
  @media (min-width: 991px) and (max-width: 1200px) {
    .header-call-us {
      padding: 22px 0;
      font-size: 12px;
    }
    .header-call-us:before {
      display: none;
    }
  }
  @media (min-width: 768px) and (max-width: 992px) {
    .left-navigation .navigation .menu-title:after {
      display: none;
    }
    .header-call-us {
      font-size: 9px;
      padding: 23px 0;
    }
    .header-call-us:before {
      display: none;
    }
  }
  @media (max-width: 767px) {
    .header-wrapper-content .header-wrapper-bottom {
      background: #f8f8f8;
    }
  }
  .footer-text {
    font: 400 14px/1.35 Poppins, Helvetica Neue, Verdana, Arial, sans-serif;
    color: #fff;
    padding: 10px 0;
  }
  .cart-footer .cart-qty {
    background: #2962ff !important;
    border-color: #2962ff !important;
  }
  body input[type="email"],
  body input[type="text"] {
    height: 34px;
  }
  .btn-primary {
    background-color: #0c79d8;
    background-repeat: repeat-x;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    border-radius: 4px 4px 4px 4px;
    border-width: 1px;
    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.2) inset,
      0 1px 2px rgba(0, 0, 0, 0.05);
    color: #fff !important;
    display: inline-block;
    opacity: 0.8;
    text-align: center;
    text-decoration: none;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    vertical-align: middle;
    text-transform: uppercase;
    padding: 6px 0;
    font-weight: 700;
    font-size: 13px;
    width: 100%;
  }
  .form-control {
    width: 315px;
    padding: 6px 12px;
    margin-bottom: 10px;
    font-size: 14px;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-transition: border-color ease-in-out 0.15s,
      -webkit-box-shadow ease-in-out 0.15s;
    -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  }
  .modal-body {
    position: relative;
    padding: 15px;
    width: 94%;
  }
  .form-control:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 8px rgba(102, 175, 233, 0.6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 8px rgba(102, 175, 233, 0.6);
  }
  body {
    overflow-x: hidden;
  }
  .cart-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 100;
    left: 0;
  }
  .footer-mini-cart {
    background: #fff;
    padding: 0;
    overflow: hidden;
    position: relative;
  }
  .footer-mini-cart:before {
    content: "";
    position: absolute;
    width: 100%;
    top: -3px;
    left: 0;
    height: 3px;
    box-shadow: 0 2px 3px #e1e1e1;
  }
  .cart-qty {
    position: absolute;
    background: #fc7070;
    color: #fff;
    top: -33px;
    font-size: 85%;
    text-transform: uppercase;
    padding: 10px 20px 7px;
    z-index: 200;
    cursor: pointer;
    z-index: 0;
  }
  .cart-qty:after {
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\f106";
    font-size: 20px;
    float: right;
    margin-top: -4px;
    -moz-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    -moz-backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    margin-left: 5px;
  }
  .cart-items {
    list-style: none;
    float: left;
    width: calc(100% - 600px);
    overflow-x: auto;
    overflow-y: hidden;
  }
  .cart-items .cart-items-inner {
    float: left;
    white-space: nowrap;
  }
  .footer-cart-additional {
    float: right;
    width: 600px;
  }
  .footer-cart-summary {
    float: left;
    width: 320px;
    padding: 24px 20px 17px;
    border-left: 1px solid #e1e1e1;
    min-height: 85px;
  }
  .cart-footer .footer-cart-actions {
    float: right;
    padding: 23px 20px 19px;
    border-left: 1px solid #e1e1e1;
    min-height: 85px;
  }
  .cart-footer .footer-cart-actions .action.primary:before {
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\f00c";
    font-size: 18px;
    -moz-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    -moz-backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    margin-right: 5px;
  }
  .minicart-wrapper {
    position: relative;
    float: right;
  }
  .minicart-wrapper .block-minicart {
    margin-bottom: 0;
  }
  .minicart-wrapper .block-minicart:after {
    left: auto;
    right: 25px;
  }
  .minicart-wrapper .block-minicart:before {
    left: auto;
    right: 26px;
  }
  .minicart-wrapper .action.showcart {
    white-space: nowrap;
    display: inline-block;
    vertical-align: middle;
    border: 0 solid;
    color: #fff;
    border-color: #006588;
    background: #006588;
  }
  .minicart-wrapper .action.showcart:before {
    padding: 7px 10px;
    min-height: 41px;
    display: inline-block;
    vertical-align: top;
    width: 40px;
    content: "";
    border-right: 0 solid #006588;
    background-color: transparent;
    /*background-image: url(https://staging.securemobile.net/pub/static/frontend/Codazon/fastest_bigmarket/en_AU/images/icon.png);*/
    background-position: 7px 8px;
    background-repeat: no-repeat;
  }
  .minicart-wrapper .action.showcart .price-label {
    padding: 10px 0 10px 15px;
    min-height: 40px;
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    vertical-align: middle;
  }
  .minicart-wrapper .action.showcart .counter.qty {
    height: 18px;
    line-height: 20px;
    background: #fc7070;
    color: #fff;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    font-size: 11px;
    display: inline-block;
    vertical-align: top;
    margin: 0;
    min-width: 18px;
    overflow: hidden;
    padding: 0 3px;
    text-align: center;
    white-space: normal;
    position: absolute;
    top: -3px;
    left: 27px;
    border: 0 solid #fff;
  }
  .block.newsletter {
    margin-bottom: 20px;
  }
  .block.newsletter .form.subscribe {
    display: table;
    width: 100%;
  }
  .block.newsletter .field {
    margin: 0 0 10px;
  }
  .block.newsletter .field .control {
    display: inline-block;
    text-decoration: none;
    display: block;
  }
  .block.newsletter .field .control:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
    line-height: 40px;
    color: #c2c2c2;
    content: "\e61d";
    font-family: luma-icons;
    margin: 0 0 0 8px;
    vertical-align: middle;
    display: inline-block;
    font-weight: 400;
    overflow: hidden;
    speak: none;
    text-align: center;
  }
  .block.newsletter .field .control:before {
    position: absolute;
  }
  .block.newsletter input {
    padding-left: 35px;
    height: 41px;
    line-height: 41px;
  }
  .block.newsletter input[type="email"] {
    background: #fff;
    color: #646464;
  }
  .block.newsletter .title {
    display: none;
  }
  .block.newsletter .actions {
    display: table-cell;
    vertical-align: top;
    width: 1%;
  }
  .block.newsletter .actions button {
    padding: 8px 20px;
  }
  .block.newsletter .action.subscribe {
    min-height: 41px;
  }
  body {
    background-color: #f8f8f8;
  }
  .page-header {
    background-color: #3cc2d0;
  }
  .page-header .panel.wrapper {
    background-color: #3cc2d0;
    color: #fff;
  }
  .header.panel {
    font: 400 11px/1.35 Poppins, Helvetica Neue, Verdana, Arial, sans-serif;
  }
  .header.panel > .header.links {
    margin: 0;
    padding: 0;
    list-style: none none;
    float: right;
    font-size: 0;
    margin-left: auto;
    margin-right: 20px;
  }
  .header.panel > .header.links > li {
    display: inline-block;
    vertical-align: top;
  }
  .header.panel > .header.links > li {
    font: 400 11px/1.35 Poppins, Helvetica Neue, Verdana, Arial, sans-serif;
    text-transform: uppercase;
    margin: 0 0 0 20px;
  }
  .header.panel > .header.links > li > a {
    color: #fff;
    text-decoration: none;
  }
  .header.panel > .header.links > li > a:visited {
    color: #fff;
    text-decoration: none;
  }
  .header.panel > .header.links > li > a:hover {
    color: #fff;
    text-decoration: underline;
  }
  .header.panel > .header.links > li > a:active {
    color: #fff;
    text-decoration: underline;
  }
  .header.content {
    padding-top: 10px;
    position: relative;
  }
  .action.skip:focus {
    background: #f0f0f0;
    padding: 10px;
    box-sizing: border-box;
    left: 0;
    position: absolute;
    text-align: center;
    top: 0;
    width: 100%;
    z-index: 15;
  }
  .page-footer {
    margin-top: 0;
  }
  .widget {
    clear: both;
  }
  .link.wishlist .counter {
    white-space: nowrap;
  }
  .link.wishlist .counter:before {
    content: "(";
  }
  .link.wishlist .counter:after {
    content: ")";
  }
  .block-static-block.widget {
    margin-bottom: 20px;
  }
  @media only screen and (max-width: 767px) {
    .footer.content,
    .header.content,
    .navigation,
    .page-header .header.panel,
    .page-main,
    .page-wrapper > .widget {
      padding-left: 15px;
      padding-right: 15px;
    }
    .navigation {
      background: 0 0 !important;
      padding: 0;
    }
    .nav-sections {
      -webkit-transition: left 0.3s;
      -moz-transition: left 0.3s;
      -ms-transition: left 0.3s;
      transition: left 0.3s;
      height: 100%;
      left: -80%;
      left: calc(-1 * (100% - 54px));
      overflow: auto;
      position: fixed;
      top: 0;
      width: 80%;
      width: calc(100% - 54px);
    }
    .nav-sections .header.links {
      margin: 0;
      padding: 0;
      list-style: none none;
    }
    .nav-sections .header.links li {
      display: block;
      margin: 0;
    }
    .nav-sections .header.links li.greet.welcome {
      border: 1px solid #424242;
      font-weight: 700;
      padding: 0.8rem 20px;
    }
    .nav-sections .header.links li > a {
      border-bottom: 1px solid #424242;
      color: #fff;
      font: 400 15px/1.35 Poppins, Helvetica Neue, Verdana, Arial, sans-serif;
    }
    .nav-sections .header.links a {
      color: #fff;
      text-decoration: none;
    }
    .nav-sections .header.links a:visited {
      color: #fff;
      text-decoration: none;
    }
    .nav-sections .header.links a:hover {
      color: #fff;
      text-decoration: none;
    }
    .nav-sections .header.links a:active {
      color: #fff;
      text-decoration: none;
    }
    .nav-sections .header.links a,
    .nav-sections .header.links a:hover {
      display: block;
      padding: 10px 20px;
      font: 400 15px/1.35 Poppins, Helvetica Neue, Verdana, Arial, sans-serif;
    }
    .nav-sections-items {
      position: relative;
      z-index: 1;
    }
    .nav-sections-items:after,
    .nav-sections-items:before {
      content: "";
      display: table;
    }
    .nav-sections-items:after {
      clear: both;
    }
    .nav-sections-item-title {
      background: #f8f8f8;
      border: 0 solid #424242;
      border-width: 0;
      box-sizing: border-box;
      float: left;
      height: 52px;
      padding-top: 18px;
      text-align: center;
      width: 33.33%;
      text-transform: uppercase;
    }
    .nav-sections-item-title .nav-sections-item-switch {
      color: #292929;
      text-decoration: none;
    }
    .nav-sections-item-title .nav-sections-item-switch:visited {
      color: #292929;
      text-decoration: none;
    }
    .nav-sections-item-title .nav-sections-item-switch:hover {
      color: #fff;
      text-decoration: none;
    }
    .nav-sections-item-title .nav-sections-item-switch:active {
      color: #fff;
      text-decoration: none;
    }
    .nav-sections-item-title .nav-sections-item-switch:hover {
      text-decoration: none;
    }
    .nav-sections-item-content {
      box-sizing: border-box;
      float: right;
      margin-left: -100%;
      margin-top: 52px;
      width: 100%;
      text-align: left;
      padding: 0;
    }
    .nav-sections-item-content:after,
    .nav-sections-item-content:before {
      content: "";
      display: table;
    }
    .nav-sections-item-content:after {
      clear: both;
    }
    .navigation {
      background: #3cc2d0;
      box-sizing: border-box;
    }
    .navigation ul {
      margin: 0;
      padding: 0;
    }
    .navigation li {
      margin: 0;
    }
    .navigation a {
      display: block;
      padding-top: 10px;
      padding-right: 0;
      padding-bottom: 10px;
      padding-left: 15px;
    }
    .navigation a,
    .navigation a:hover {
      color: #575757;
      text-decoration: none;
    }
    .navigation .level0 {
      border-top: 1px solid #d1d1d1;
      font-size: 1.6rem;
    }
    .navigation .level0 > .level-top {
      font-weight: 700;
      padding: 8px 40px 8px 15px;
      text-transform: uppercase;
      word-wrap: break-word;
    }
    .navigation .level0 > .level-top:hover {
      color: #333;
    }
    .navigation li.level0:last-child {
      border-bottom: 1px solid #d1d1d1;
    }
    .navigation a {
      color: #fff;
      text-decoration: none;
    }
    .navigation a:visited {
      color: #fff;
      text-decoration: none;
    }
    .navigation a:hover {
      color: #fff;
      text-decoration: none;
    }
    .navigation a:active {
      color: #fff;
      text-decoration: none;
    }
    .navigation li.level0 {
      margin-bottom: 3px;
      border: 1px solid #424242;
    }
    .navigation li.level0:last-child {
      border-bottom: 1px solid #424242;
    }
    .navigation li.level0 > .level-top {
      padding: 12px 20px;
      font: 400 15px/1.35 Poppins, Helvetica Neue, Verdana, Arial, sans-serif;
    }
    .navigation .level0 {
      border-top: 1px solid #424242;
    }
    .left-navigation {
      margin-bottom: 20px;
    }
    .left-navigation .menu-title {
      margin-bottom: 5px;
    }
    .columns .column.main {
      max-width: 100%;
    }
    .cart-footer .cart-qty {
      font-size: 0;
    }
    .page-header {
      margin-bottom: 15px;
    }
    .header.content > .row > [class*="col-sm-"] {
      position: static;
    }
    .page-header {
      margin-bottom: 0;
    }
    .page-header .minicart-wrapper {
      margin: 0;
      position: static;
    }
    .page-header .minicart-wrapper .action.showcart {
      position: absolute;
      top: 15px;
      right: 15px;
      z-index: 5;
    }
    .page-header .minicart-wrapper .action.showcart {
      vertical-align: top;
      padding: 0;
      min-width: 0;
      border: 1px solid #fff;
      width: 35px;
      height: 35px;
      line-height: 35px;
      color: #fff;
    }
    .page-header .minicart-wrapper .action.showcart:before {
      font-size: 24px;
      padding: 0;
      width: 33px;
      height: 33px;
      line-height: 33px;
      min-height: 33px;
      border: 0;
    }
    .page-header .minicart-wrapper .action.showcart:hover {
      color: #fff;
      background-color: #006588;
    }
    .page-header .minicart-wrapper .action.showcart .price-label,
    .page-header .minicart-wrapper .action.showcart .text {
      display: none;
    }
    .page-header .minicart-wrapper .action.showcart .counter.qty {
      top: -10px;
      left: auto;
      right: -9px;
      z-index: 1;
    }
    .footer-box {
      clear: both;
      padding-top: 20px;
    }
    .page-footer .copyright {
      float: none;
      text-align: center;
      display: inline-block;
      width: 100%;
    }
    .page-footer .footer-custom-block {
      float: none;
      clear: both;
      display: inline-block;
      text-align: center;
      width: 100%;
      padding-top: 10px;
    }
    .page-footer .footer-custom-block .pull-right {
      float: none !important;
      display: inline-block;
    }
    .page-footer .footer-container-wrapper {
      padding-top: 0;
    }
    .page-footer .footer-box p.h5 {
      cursor: pointer;
      text-transform: capitalize;
      font-style: italic;
      margin-bottom: 10px;
    }
    .page-footer .footer-box p.h5:after {
      display: inline-block;
      font-family: FontAwesome;
      font-style: normal;
      font-weight: 400;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "\f105";
      font-size: 24px;
      float: right;
      margin-top: -3px;
      -moz-transition: all 0.5s ease;
      -webkit-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;
      transition: all 0.5s ease;
      -moz-backface-visibility: hidden;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
    }
    .page-footer .showhide {
      height: 0;
      visibility: hidden;
      overflow: hidden;
      -webkit-opacity: 0;
      -moz-opacity: 0;
      opacity: 0;
      -moz-transition: all 0.5s ease;
      -webkit-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;
      transition: all 0.5s ease;
      -moz-transition: opacity 0.4s ease, transform 0.4s ease,
        visibility 0.4s linear 0s;
      -webkit-transition: opacity 0.4s ease, transform 0.4s ease,
        visibility 0.4s linear 0s;
      -o-transition: opacity 0.4s ease, transform 0.4s ease,
        visibility 0.4s linear 0s;
      transition: opacity 0.4s ease, transform 0.4s ease,
        visibility 0.4s linear 0s;
      -moz-backface-visibility: hidden;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      -moz-transform: translateY(20px);
      -webkit-transform: translateY(20px);
      -o-transform: translateY(20px);
      transform: translateY(20px);
    }
    .left-navigation {
      margin-bottom: 15px;
      margin-top: 15px;
    }
    .header-bottom-right {
      float: none !important;
      text-align: center;
    }
    .header-call-us {
      padding: 5px 0;
    }
    .footer-text {
      text-align: center;
    }
    .footer-text span.pull-right {
      float: none !important;
    }
    .cart-qty {
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      top: -63px;
      left: 10px;
      padding: 0;
      width: 50px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      -moz-transition: all 0.5s ease;
      -webkit-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;
      transition: all 0.5s ease;
      -moz-backface-visibility: hidden;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
    }
    .cart-qty:after {
      float: none;
      line-height: 50px;
      margin: 0;
      content: "\f291";
    }
    .cart-qty span {
      display: none;
    }
    .cart-items {
      width: 100%;
    }
    .footer-cart-additional {
      clear: both;
      width: 100%;
    }
    .footer-cart-additional .footer-cart-summary {
      width: 100%;
      clear: both;
      border: 0;
    }
    .footer-cart-additional .footer-cart-actions {
      width: 100%;
      clear: both;
      border: 0;
      border-top: 1px solid #e1e1e1;
    }
    .footer-cart-additional .footer-cart-actions .action.primary {
      width: 100%;
    }
    .minicart-wrapper .action.showcart .price-label {
      display: none;
    }
  }
} /*! CSS Used from: https://staging.securemobile.net/pub/static/frontend/Codazon/fastest_bigmarket/en_AU/Codazon_OneStepCheckout/custom.css ; media=all */
@media all {
  .modal-content {
    padding-bottom: 0 !important;
  }
} /*! CSS Used from: https://staging.securemobile.net/pub/static/frontend/Codazon/fastest_bigmarket/en_AU/Webkul_Marketplace/css/wk_block.css ; media=all */
@media all {
  .btn-primary {
    background-color: #0c79d8;
    background-repeat: repeat-x;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    border-radius: 4px 4px 4px 4px;
    border-width: 1px;
    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.2) inset,
      0 1px 2px rgba(0, 0, 0, 0.05);
    color: #fff !important;
    display: inline-block;
    opacity: 0.8;
    text-align: center;
    text-decoration: none;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    vertical-align: middle;
    text-transform: uppercase;
    padding: 6px 0;
    font-weight: 700;
    font-size: 13px;
    width: 100%;
  }
  .form-control {
    width: 315px;
    padding: 6px 12px;
    margin-bottom: 10px;
    font-size: 14px;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-transition: border-color ease-in-out 0.15s,
      -webkit-box-shadow ease-in-out 0.15s;
    -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  }
  .modal-body {
    position: relative;
    padding: 15px;
    width: 90%;
  }
  .form-control:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 8px rgba(102, 175, 233, 0.6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 8px rgba(102, 175, 233, 0.6);
  }
  @media only screen and (max-width: 200px) {
    .modal-body {
      padding: 10px;
    }
  }
} /*! CSS Used from: https://staging.securemobile.net/pub/static/frontend/Codazon/fastest_bigmarket/en_AU/css/styles-l.css ; media=screen and (min-width: 768px) */
@media screen and (min-width: 768px) {
  body {
    color: #282828;
    font: 400 12px/1.35 Poppins, Helvetica Neue, Verdana, Arial, sans-serif;
  }
  body input[type="email"],
  body input[type="text"] {
    height: 40px;
    color: #282828;
    border-color: #dbdbdb;
    background-color: #fff;
    font: 400 12px/1.35 Poppins, Helvetica Neue, Verdana, Arial, sans-serif;
  }
  body textarea {
    color: #282828;
    border-color: #dbdbdb;
    background-color: #fff;
    font: 400 12px/1.35 Poppins, Helvetica Neue, Verdana, Arial, sans-serif;
  }
  body input:not([disabled]):focus,
  body textarea:not([disabled]):focus {
    box-shadow: 0 1px 3px #e1e1e1;
  }
  h2 {
    font: 500 20px/1.35 Poppins, Helvetica Neue, Verdana, Arial, sans-serif;
    margin: 0 0 20px;
  }
  h3 {
    font: 500 18px/1.35 Poppins, Helvetica Neue, Verdana, Arial, sans-serif;
    margin: 0 0 20px;
  }
  h4 {
    font: 400 16px/1.35 Poppins, Helvetica Neue, Verdana, Arial, sans-serif;
    margin: 0 0 10px;
  }
  .h5 {
    font: 400 14px/1.35 Poppins, Helvetica Neue, Verdana, Arial, sans-serif;
    margin: 0 0 10px;
  }
  .h6,
  h6 {
    font: 400 11px/1.35 Poppins, Helvetica Neue, Verdana, Arial, sans-serif;
    margin: 0 0 10px;
  }
  a {
    color: #282828;
  }
  a:hover {
    color: #006588;
  }
  .text-center {
    text-align: center;
  }
  .text-uppercase {
    text-transform: uppercase;
  }
  .header a {
    color: #fff;
  }
  .header a:hover {
    color: #006588;
    text-decoration: none;
  }
  body {
    overflow-x: hidden;
    /*background: #f8f8f8 url(https://staging.securemobile.net/pub/static/frontend/Codazon/fastest_bigmarket/en_AU/images/transparent.png) 0 0 no-repeat;*/
  }
  .page-header {
    /*background: #3cc2d0 url(https://staging.securemobile.net/pub/static/frontend/Codazon/fastest_bigmarket/en_AU/images/transparent.png) 0 0 no-repeat fixed;*/
  }
  .page-footer {
    /*background: #fff url(https://staging.securemobile.net/pub/static/frontend/Codazon/fastest_bigmarket/en_AU/images/transparent.png) 0 0 no-repeat;*/
  }
  .page-main {
    padding-top: 20px;
  }
  button {
    text-transform: uppercase;
    -moz-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    -moz-backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  button {
    color: #fff;
    background-color: #006588;
    border-color: #006588;
    font: 500 14px/1.35 Poppins, Helvetica Neue, Verdana, Arial, sans-serif;
  }
  button:hover {
    color: #006588;
    background-color: #fff;
    border-color: #006588;
  }
  button.primary {
    color: #fff;
    background-color: #006588;
    border-color: #006588;
  }
  button.primary:hover {
    color: #fff;
    background-color: #006588;
    border-color: #006588;
  }
  .loader {
    position: relative;
    text-align: center;
    padding: 100px 0;
  }
  .loader img {
    display: none;
  }
  .loader:after,
  .loader:before {
    position: absolute;
    content: "";
    border: 2px solid #dbdbdb;
    width: 31px;
    height: 31px;
  }
  .loader:after {
    animation: cssload-spinner1 2.88s linear infinite;
    -o-animation: cssload-spinner1 2.88s linear infinite;
    -ms-animation: cssload-spinner1 2.88s linear infinite;
    -webkit-animation: cssload-spinner1 2.88s linear infinite;
    -moz-animation: cssload-spinner1 2.88s linear infinite;
  }
  .loader:before {
    width: 43px;
    height: 43px;
    margin-left: -6.5px;
    margin-top: -6.5px;
    animation: cssload-spinner2 2.88s linear infinite;
    -o-animation: cssload-spinner2 2.88s linear infinite;
    -ms-animation: cssload-spinner2 2.88s linear infinite;
    -webkit-animation: cssload-spinner2 2.88s linear infinite;
    -moz-animation: cssload-spinner2 2.88s linear infinite;
  }
  @media (max-width: 767px) {
    .hidden-xs {
      display: none !important;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .hidden-sm {
      display: none !important;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .hidden-md {
      display: none !important;
    }
  }
  .pull-right {
    float: right !important;
  }
  .img-responsive {
    display: block;
    max-width: 100%;
    height: auto;
  }
  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  :after,
  :before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .row {
    margin-left: -10px;
    margin-right: -10px;
  }
  .row:after,
  .row:before {
    content: " ";
    display: table;
  }
  .row:after {
    clear: both;
  }
  .row {
    margin-left: -10px;
    margin-right: -10px;
  }
  .row:after,
  .row:before {
    content: " ";
    display: table;
  }
  .row:after {
    clear: both;
  }
  .col-md-14,
  .col-md-4,
  .col-md-7,
  .col-sm-10,
  .col-sm-12,
  .col-sm-14,
  .col-sm-4,
  .col-sm-6 {
    position: relative;
    min-height: 1px;
    padding-left: 10px;
    padding-right: 10px;
  }
  @media (min-width: 768px) {
    .col-sm-10,
    .col-sm-12,
    .col-sm-14,
    .col-sm-4,
    .col-sm-6 {
      float: left;
    }
    .col-sm-4 {
      width: 16.66667%;
    }
    .col-sm-6 {
      width: 25%;
    }
    .col-sm-10 {
      width: 41.66667%;
    }
    .col-sm-12 {
      width: 50%;
    }
    .col-sm-14 {
      width: 58.33333%;
    }
  }
  @media (min-width: 992px) {
    .col-md-14,
    .col-md-4,
    .col-md-7 {
      float: left;
    }
    .col-md-4 {
      width: 16.66667%;
    }
    .col-md-7 {
      width: 29.16667%;
    }
    .col-md-14 {
      width: 58.33333%;
    }
  }
  .header-wrapper-bottom {
    background-color: #3cc2d0;
  }
  .header-wrapper-bottom .header.content {
    padding-top: 0;
    padding-bottom: 0;
  }
  .header-main-menu .nav-sections {
    float: left;
    clear: both;
  }
  .cdz-main-menu ul {
    margin: 0;
    padding: 0;
    list-style: none none;
  }
  .cart-footer .cart-qty {
    color: #fff;
    background-color: #006588;
    border-color: #006588;
    font: 400 11px/1.35 Poppins, Helvetica Neue, Verdana, Arial, sans-serif;
  }
  .page-footer {
    background-color: #fff;
    color: #777;
  }
  .page-footer li,
  .page-footer ul {
    margin: 0;
    padding: 0;
    list-style: none none;
  }
  .page-footer .top-footer-wrapper {
    background-color: #006588;
  }
  .page-footer .footer-container-wrapper {
    padding-top: 50px;
    padding-bottom: 30px;
  }
  .page-footer .footer-content-top {
    padding-top: 30px;
    padding-bottom: 20px;
  }
  .page-footer .footer-content-top .block-static-block.widget {
    margin-bottom: 0;
  }
  .page-footer .block.newsletter {
    margin-bottom: 0;
  }
  .page-footer .block.newsletter .field .control {
    margin-right: 10px;
  }
  .page-footer .block.newsletter input {
    border-width: 1px;
    border-color: #fff;
    background: 0 0;
  }
  .page-footer .block.newsletter .action.subscribe {
    border-width: 1px;
    color: #fff;
    background-color: transparent;
    border-color: #fff;
  }
  .page-footer .block.newsletter .action.subscribe:hover {
    color: #006588;
    background-color: #fff;
    border-color: #006588;
  }
  .page-footer a {
    color: #777;
  }
  .page-footer a:hover {
    color: #006588;
  }
  .page-footer p.h5 {
    color: #3a3a3a;
    font-weight: 600;
    margin-bottom: 25px;
    text-transform: uppercase;
  }
  .page-footer .footer-link {
    margin-top: -10px;
  }
  .page-footer .footer-link li {
    padding: 10px 0;
    border-bottom: 1px solid #e8e8e8;
  }
  .page-footer .footer-link li a {
    display: block;
    -moz-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    -moz-backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  .page-footer .footer-link li.last {
    border-bottom: none;
  }
  .page-footer .bottom-footer-wrapper {
    background: #e8e8e8;
    padding: 30px 0;
    overflow: hidden;
    border-top: 1px solid #e8e8e8;
  }
  .page-footer .bottom-footer-wrapper .block-static-block.widget {
    margin-bottom: 0;
  }
  .page-footer .copyright {
    font: 400 14px/1.35 Poppins, Helvetica Neue, Verdana, Arial, sans-serif;
    float: left;
  }
  .page-footer .footer-custom-block {
    float: right;
  }
  .page-layout-1column .columns {
    margin-bottom: 40px;
    background-color: #fff;
    padding: 20px 10px;
    box-shadow: 0 0 1px #8c8686;
    -moz-box-shadow: 0 0 1px #8c8686;
    -webkit-box-shadow: 0 0 1px #8c8686;
    -o-box-shadow: 0 0 1px #8c8686;
    -ms-box-shadow: 0 0 1px #8c8686;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    margin-left: 0;
    margin-right: 0;
  }
  .page-layout-1column .columns .column.main {
    padding-bottom: 0;
  }
  .header-bottom-right .block-static-block.widget {
    margin-bottom: 0;
  }
  .header-call-us {
    color: #fff;
    padding: 20px 0;
    font: 400 14px/1.35 Poppins, Helvetica Neue, Verdana, Arial, sans-serif;
  }
  .header-call-us:before {
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\f095";
    font-size: 16px;
    padding: 0 5px;
  }
  .float-bar {
    position: fixed;
    -moz-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    -moz-backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    z-index: 2;
    width: 80px;
    border: 1px solid #dbdbdb;
    background: #fff;
  }
  .float-bar .item {
    text-align: center;
    padding: 10px 7px;
    margin: 0;
    font-size: 11px;
    border-bottom: 1px solid #dbdbdb;
  }
  .float-bar .item.last {
    border-bottom: 0;
  }
  .float-bar .item a {
    color: #282828;
    text-decoration: none;
  }
  .float-bar .item a:visited {
    color: #282828;
    text-decoration: none;
  }
  .float-bar .item a:hover {
    color: #006588;
    text-decoration: none;
  }
  .float-bar .item a:active {
    color: #006588;
    text-decoration: none;
  }
  .float-bar .item a span {
    display: block;
  }
  .float-bar .item a .fa {
    margin-bottom: 5px;
    color: #006588;
  }
  .float-bar .item a .fa:before {
    font-size: 18px;
  }
  .left-navigation .navigation .menu-title {
    text-transform: uppercase;
    background: #006588;
  }
  @media (min-width: 768px) and (max-width: 1200px) {
    .page-header .header-wrapper-bottom .header.content {
      padding: 0 10px;
    }
  }
  @media (min-width: 991px) and (max-width: 1200px) {
    .header-call-us {
      padding: 22px 0;
      font-size: 12px;
    }
    .header-call-us:before {
      display: none;
    }
  }
  @media (min-width: 768px) and (max-width: 992px) {
    .left-navigation .navigation .menu-title:after {
      display: none;
    }
    .header-call-us {
      font-size: 9px;
      padding: 23px 0;
    }
    .header-call-us:before {
      display: none;
    }
  }
  @media (max-width: 767px) {
    .header-wrapper-content .header-wrapper-bottom {
      background: #f8f8f8;
    }
  }
  .footer-text {
    font: 400 14px/1.35 Poppins, Helvetica Neue, Verdana, Arial, sans-serif;
    color: #fff;
    padding: 10px 0;
  }
  .cart-footer .cart-qty {
    background: #2962ff !important;
    border-color: #2962ff !important;
  }
  .btn-primary {
    background-color: #0c79d8;
    background-repeat: repeat-x;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    border-radius: 4px 4px 4px 4px;
    border-width: 1px;
    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.2) inset,
      0 1px 2px rgba(0, 0, 0, 0.05);
    color: #fff !important;
    display: inline-block;
    opacity: 0.8;
    text-align: center;
    text-decoration: none;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    vertical-align: middle;
    text-transform: uppercase;
    padding: 6px 0;
    font-weight: 700;
    font-size: 13px;
    width: 100%;
  }
  .form-control {
    width: 315px;
    padding: 6px 12px;
    margin-bottom: 10px;
    font-size: 14px;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-transition: border-color ease-in-out 0.15s,
      -webkit-box-shadow ease-in-out 0.15s;
    -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  }
  .modal-body {
    position: relative;
    padding: 15px;
    width: 94%;
  }
  .form-control:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 8px rgba(102, 175, 233, 0.6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 8px rgba(102, 175, 233, 0.6);
  }
  body {
    overflow-x: hidden;
  }
  .cart-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 100;
    left: 0;
  }
  .footer-mini-cart {
    background: #fff;
    padding: 0;
    overflow: hidden;
    position: relative;
  }
  .footer-mini-cart:before {
    content: "";
    position: absolute;
    width: 100%;
    top: -3px;
    left: 0;
    height: 3px;
    box-shadow: 0 2px 3px #e1e1e1;
  }
  .cart-qty {
    position: absolute;
    background: #fc7070;
    color: #fff;
    top: -33px;
    font-size: 85%;
    text-transform: uppercase;
    padding: 10px 20px 7px;
    z-index: 200;
    cursor: pointer;
    z-index: 0;
  }
  .cart-qty:after {
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\f106";
    font-size: 20px;
    float: right;
    margin-top: -4px;
    -moz-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    -moz-backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    margin-left: 5px;
  }
  .cart-items {
    list-style: none;
    float: left;
    width: calc(100% - 600px);
    overflow-x: auto;
    overflow-y: hidden;
  }
  .cart-items .cart-items-inner {
    float: left;
    white-space: nowrap;
  }
  .footer-cart-additional {
    float: right;
    width: 600px;
  }
  .footer-cart-summary {
    float: left;
    width: 320px;
    padding: 24px 20px 17px;
    border-left: 1px solid #e1e1e1;
    min-height: 85px;
  }
  .cart-footer .footer-cart-actions {
    float: right;
    padding: 23px 20px 19px;
    border-left: 1px solid #e1e1e1;
    min-height: 85px;
  }
  .cart-footer .footer-cart-actions .action.primary:before {
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\f00c";
    font-size: 18px;
    -moz-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    -moz-backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    margin-right: 5px;
  }
  @media all and (min-width: 768px), print {
    .header.content:after,
    .header.content:before,
    .page-header .header.panel:after,
    .page-header .header.panel:before {
      content: "";
      display: table;
    }
    .header.content:after,
    .page-header .header.panel:after {
      clear: both;
    }
    .column.main {
      box-sizing: border-box;
    }
    .footer.content,
    .header.content,
    .navigation,
    .page-header .header.panel,
    .page-main,
    .page-wrapper > .widget {
      padding-left: 10px;
      padding-right: 10px;
      margin-left: auto;
      margin-right: auto;
      width: auto;
      max-width: 1200px;
      box-sizing: border-box;
    }
    .page-main {
      width: 100%;
      -webkit-flex-grow: 1;
      flex-grow: 1;
      -webkit-flex-shrink: 0;
      flex-shrink: 0;
      -webkit-flex-basis: auto;
      flex-basis: auto;
    }
    .columns {
      display: block;
      margin-left: -10px;
      margin-right: -10px;
    }
    .column.main {
      min-height: 300px;
      padding-left: 10px;
      padding-right: 10px;
    }
    .page-layout-1column .column.main {
      width: 100%;
      -ms-flex-order: 2;
      -webkit-order: 2;
      order: 2;
    }
    .panel.header {
      padding: 10px 20px;
    }
    .nav-toggle {
      display: none;
    }
    .navigation ul {
      padding: 0;
    }
    .nav-sections {
      -webkit-flex-shrink: 0;
      flex-shrink: 0;
      -webkit-flex-basis: auto;
      flex-basis: auto;
    }
    .nav-sections-item-title {
      display: none;
    }
    .nav-sections-item-content {
      display: block;
    }
    .nav-sections-item-content > * {
      display: none;
    }
    .nav-sections-item-content > .navigation {
      display: block;
    }
    .navigation {
      background: #3cc2d0;
      font-weight: 700;
      height: inherit;
      left: auto;
      overflow: inherit;
      padding: 0;
      position: relative;
      top: 0;
      width: 100%;
      z-index: 3;
    }
    .navigation:empty {
      display: none;
    }
    .navigation ul {
      margin-top: 0;
      margin-bottom: 0;
      padding: 0;
      position: relative;
    }
    .navigation li.level0 {
      border-top: none;
    }
    .navigation .level0 {
      margin: 0 10px 0 0;
      display: inline-block;
      position: relative;
    }
    .navigation .level0:last-child {
      margin-right: 0;
      padding-right: 0;
    }
    .navigation .level0:hover:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 100%;
      width: 10px;
      height: calc(103%);
      z-index: 1;
    }
    .navigation .level0 > .level-top {
      color: #fff;
      line-height: 47px;
      padding: 0 12px;
      text-decoration: none;
      box-sizing: border-box;
      position: relative;
      display: inline-block;
    }
    .navigation .level0 > .level-top:hover {
      background: #006588;
      color: #fff;
      text-decoration: none;
    }
    .navigation ul {
      padding: 0;
    }
    .navigation li.level0 {
      margin: 0;
    }
    .navigation li.level0 > .level-top {
      text-decoration: none;
      color: #fff;
      background-color: #3cc2d0;
      border-color: #fff;
      font: 400 15px/1.35 Poppins, Helvetica Neue, Verdana, Arial, sans-serif;
      text-transform: uppercase;
      padding: 12px 20px;
      vertical-align: top;
      display: inline-block;
      -moz-transition: all 0.5s ease;
      -webkit-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;
      transition: all 0.5s ease;
      -moz-backface-visibility: hidden;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
    }
    .navigation li.level0 > .level-top:visited {
      color: #fff;
      text-decoration: none;
    }
    .navigation li.level0 > .level-top:hover {
      color: #fff;
      text-decoration: none;
    }
    .navigation li.level0 > .level-top:active {
      color: #fff;
      text-decoration: none;
    }
    .panel.header .links {
      display: inline-block;
    }
    .left-navigation .block {
      margin-bottom: 0 !important;
    }
    .header-main-menu .nav-sections {
      width: 100%;
    }
    .left-navigation {
      margin-top: 0;
    }
    .left-navigation .navigation .menu-title {
      padding: 19px 10px 19px 20px;
      min-height: 60px;
    }
    .minicart-wrapper .block-minicart {
      right: 0;
    }
    .block.newsletter {
      width: 100%;
    }
    body {
      height: 100%;
    }
    .page-header {
      border: 0;
      margin-bottom: 0;
    }
    .page-header .panel.wrapper {
      border-bottom: 1px solid #006588;
      background-color: #3cc2d0;
    }
    .page-header .header.panel {
      padding-bottom: 5px;
      padding-top: 5px;
    }
    .header.panel > .header.links {
      margin: 0;
      padding: 0;
      list-style: none none;
      float: right;
      margin-left: auto;
    }
    .header.panel > .header.links > li {
      display: inline-block;
      vertical-align: top;
    }
    .header.panel > .header.links > li {
      margin: 0 0 0 20px;
    }
    .header.panel > .header.links > li > a {
      display: inline-block;
      vertical-align: middle;
      color: #fff;
      text-decoration: none;
    }
    .header.panel > .header.links > li > a:visited {
      color: #fff;
      text-decoration: none;
    }
    .header.panel > .header.links > li > a:hover {
      color: #006588;
      text-decoration: none;
    }
    .header.panel > .header.links > li > a:active {
      color: #006588;
      text-decoration: none;
    }
    .header.panel > .header.links > li.welcome {
      display: none;
    }
    .header.content {
      padding: 30px 10px 30px;
    }
    .page-wrapper {
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      margin: 0;
      min-height: 100%;
      position: relative;
      transition: margin 0.3s ease-out 0;
    }
    .page-wrapper > .widget {
      box-sizing: border-box;
      width: 100%;
    }
    .footer.content {
      border-top: none;
    }
  }
} /*! CSS Used from: Embedded */
ul.thumbs-up {
  list-style-image: url("../img/yellow-thumbs-up.png");
} /*! CSS Used keyframes */
@keyframes cssload-spinner1 {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes cssload-spinner1 {
  from {
    -o-transform: rotate(0);
  }
  to {
    -o-transform: rotate(360deg);
  }
}
@-ms-keyframes cssload-spinner1 {
  from {
    -ms-transform: rotate(0);
  }
  to {
    -ms-transform: rotate(360deg);
  }
}
@-webkit-keyframes cssload-spinner1 {
  from {
    -webkit-transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes cssload-spinner1 {
  from {
    -moz-transform: rotate(0);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}
@keyframes cssload-spinner1 {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes cssload-spinner1 {
  from {
    -o-transform: rotate(0);
  }
  to {
    -o-transform: rotate(360deg);
  }
}
@-ms-keyframes cssload-spinner1 {
  from {
    -ms-transform: rotate(0);
  }
  to {
    -ms-transform: rotate(360deg);
  }
}
@-webkit-keyframes cssload-spinner1 {
  from {
    -webkit-transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes cssload-spinner1 {
  from {
    -moz-transform: rotate(0);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}
@keyframes cssload-spinner2 {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(-360deg);
  }
}
@-o-keyframes cssload-spinner2 {
  from {
    -o-transform: rotate(0);
  }
  to {
    -o-transform: rotate(-360deg);
  }
}
@-ms-keyframes cssload-spinner2 {
  from {
    -ms-transform: rotate(0);
  }
  to {
    -ms-transform: rotate(-360deg);
  }
}
@-webkit-keyframes cssload-spinner2 {
  from {
    -webkit-transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(-360deg);
  }
}
@-moz-keyframes cssload-spinner2 {
  from {
    -moz-transform: rotate(0);
  }
  to {
    -moz-transform: rotate(-360deg);
  }
}
@keyframes cssload-spinner2 {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(-360deg);
  }
}
@-o-keyframes cssload-spinner2 {
  from {
    -o-transform: rotate(0);
  }
  to {
    -o-transform: rotate(-360deg);
  }
}
@-ms-keyframes cssload-spinner2 {
  from {
    -ms-transform: rotate(0);
  }
  to {
    -ms-transform: rotate(-360deg);
  }
}
@-webkit-keyframes cssload-spinner2 {
  from {
    -webkit-transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(-360deg);
  }
}
@-moz-keyframes cssload-spinner2 {
  from {
    -moz-transform: rotate(0);
  }
  to {
    -moz-transform: rotate(-360deg);
  }
} /*! CSS Used fontfaces */
@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUOjIg1_i6t8kCHKm459WxZqh7p29NfpiOjk20.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUOjIg1_i6t8kCHKm459WxZqh7g29NfpiOjk20.woff2)
    format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUOjIg1_i6t8kCHKm459WxZqh7r29NfpiOjk20.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUOjIg1_i6t8kCHKm459WxZqh7q29NfpiOjk20.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUOjIg1_i6t8kCHKm459WxZqh7k29NfpiOj.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUPjIg1_i6t8kCHKm459WxZBg_z8fZwjimrq1Q_.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUPjIg1_i6t8kCHKm459WxZBg_z-PZwjimrq1Q_.woff2)
    format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUPjIg1_i6t8kCHKm459WxZBg_z8_Zwjimrq1Q_.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUPjIg1_i6t8kCHKm459WxZBg_z8vZwjimrq1Q_.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUPjIg1_i6t8kCHKm459WxZBg_z_PZwjimrqw.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUPjIg1_i6t8kCHKm459WxZYgzz8fZwjimrq1Q_.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUPjIg1_i6t8kCHKm459WxZYgzz-PZwjimrq1Q_.woff2)
    format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUPjIg1_i6t8kCHKm459WxZYgzz8_Zwjimrq1Q_.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUPjIg1_i6t8kCHKm459WxZYgzz8vZwjimrq1Q_.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUPjIg1_i6t8kCHKm459WxZYgzz_PZwjimrqw.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUQjIg1_i6t8kCHKm459WxRxC7m0dR9pBOi.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUQjIg1_i6t8kCHKm459WxRzS7m0dR9pBOi.woff2)
    format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUQjIg1_i6t8kCHKm459WxRxi7m0dR9pBOi.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUQjIg1_i6t8kCHKm459WxRxy7m0dR9pBOi.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUQjIg1_i6t8kCHKm459WxRyS7m0dR9pA.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUPjIg1_i6t8kCHKm459WxZOg3z8fZwjimrq1Q_.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUPjIg1_i6t8kCHKm459WxZOg3z-PZwjimrq1Q_.woff2)
    format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUPjIg1_i6t8kCHKm459WxZOg3z8_Zwjimrq1Q_.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUPjIg1_i6t8kCHKm459WxZOg3z8vZwjimrq1Q_.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUPjIg1_i6t8kCHKm459WxZOg3z_PZwjimrqw.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUQjIg1_i6t8kCHKm45_QpRxC7m0dR9pBOi.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUQjIg1_i6t8kCHKm45_QpRzS7m0dR9pBOi.woff2)
    format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUQjIg1_i6t8kCHKm45_QpRxi7m0dR9pBOi.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUQjIg1_i6t8kCHKm45_QpRxy7m0dR9pBOi.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUQjIg1_i6t8kCHKm45_QpRyS7m0dR9pA.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_aZA3gTD_vx3rCubqg.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_aZA3g3D_vx3rCubqg.woff2)
    format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_aZA3gbD_vx3rCubqg.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_aZA3gfD_vx3rCubqg.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_aZA3gnD_vx3rCs.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_cJD3gTD_vx3rCubqg.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_cJD3g3D_vx3rCubqg.woff2)
    format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_cJD3gbD_vx3rCubqg.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_cJD3gfD_vx3rCubqg.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_cJD3gnD_vx3rCs.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUSjIg1_i6t8kCHKm459WRhyyTh89ZNpQ.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUSjIg1_i6t8kCHKm459W1hyyTh89ZNpQ.woff2)
    format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUSjIg1_i6t8kCHKm459WZhyyTh89ZNpQ.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUSjIg1_i6t8kCHKm459WdhyyTh89ZNpQ.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_ZpC3gTD_vx3rCubqg.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_ZpC3g3D_vx3rCubqg.woff2)
    format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_ZpC3gbD_vx3rCubqg.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_ZpC3gfD_vx3rCubqg.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_ZpC3gnD_vx3rCs.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: Pe-icon-7-stroke;
  src: url("../fonts/Pe-icon-7-stroke.eot?d7yf1v");
  src: url("../fonts/Pe-icon-7-stroke.eot#iefixd7yf1v")
      format("embedded-opentype"),
    url("../fonts/Pe-icon-7-stroke.woff?d7yf1v") format("woff"),
    url("../fonts/Pe-icon-7-stroke.ttf?d7yf1v") format("truetype"),
    url("../fonts/Pe-icon-7-stroke.svg?d7yf1v#Pe-icon-7-stroke") format("svg");
  font-weight: 400;
  font-style: normal;
}
/*@font-face {*/
/*    font-family: Poppins;*/
/*    src: url(https://staging.securemobile.net/pub/static/frontend/Codazon/fastest_bigmarket/en_AU/fonts/opensans/light/opensans-300.woff2) format("woff2"),*/
/*    url(https://staging.securemobile.net/pub/static/frontend/Codazon/fastest_bigmarket/en_AU/fonts/opensans/light/opensans-300.woff) format("woff");*/
/*    font-weight: 300;*/
/*    font-style: normal;*/
/*    font-display: auto;*/
/*}*/
/*@font-face {*/
/*    font-family: Poppins;*/
/*    src: url(https://staging.securemobile.net/pub/static/frontend/Codazon/fastest_bigmarket/en_AU/fonts/opensans/regular/opensans-400.woff2) format("woff2"),*/
/*    url(https://staging.securemobile.net/pub/static/frontend/Codazon/fastest_bigmarket/en_AU/fonts/opensans/regular/opensans-400.woff) format("woff");*/
/*    font-weight: 400;*/
/*    font-style: normal;*/
/*    font-display: auto;*/
/*}*/
/*@font-face {*/
/*    font-family: Poppins;*/
/*    src: url(https://staging.securemobile.net/pub/static/frontend/Codazon/fastest_bigmarket/en_AU/fonts/opensans/semibold/opensans-600.woff2) format("woff2"),*/
/*    url(https://staging.securemobile.net/pub/static/frontend/Codazon/fastest_bigmarket/en_AU/fonts/opensans/semibold/opensans-600.woff) format("woff");*/
/*    font-weight: 600;*/
/*    font-style: normal;*/
/*    font-display: auto;*/
/*}*/
/*@font-face {*/
/*    font-family: Poppins;*/
/*    src: url(https://staging.securemobile.net/pub/static/frontend/Codazon/fastest_bigmarket/en_AU/fonts/opensans/bold/opensans-700.woff2) format("woff2"),*/
/*    url(https://staging.securemobile.net/pub/static/frontend/Codazon/fastest_bigmarket/en_AU/fonts/opensans/bold/opensans-700.woff) format("woff");*/
/*    font-weight: 700;*/
/*    font-style: normal;*/
/*    font-display: auto;*/
/*}*/
/*@font-face {*/
/*    font-family: Poppins;*/
/*    src: url(https://staging.securemobile.net/pub/static/frontend/Codazon/fastest_bigmarket/en_AU/fonts/opensans/light/opensans-300.woff2) format("woff2"),*/
/*    url(https://staging.securemobile.net/pub/static/frontend/Codazon/fastest_bigmarket/en_AU/fonts/opensans/light/opensans-300.woff) format("woff");*/
/*    font-weight: 300;*/
/*    font-style: normal;*/
/*    font-display: auto;*/
/*}*/
/*@font-face {*/
/*    font-family: Poppins;*/
/*    src: url(https://staging.securemobile.net/pub/static/frontend/Codazon/fastest_bigmarket/en_AU/fonts/opensans/regular/opensans-400.woff2) format("woff2"),*/
/*    url(https://staging.securemobile.net/pub/static/frontend/Codazon/fastest_bigmarket/en_AU/fonts/opensans/regular/opensans-400.woff) format("woff");*/
/*    font-weight: 400;*/
/*    font-style: normal;*/
/*    font-display: auto;*/
/*}*/
/*@font-face {*/
/*    font-family: Poppins;*/
/*    src: url(https://staging.securemobile.net/pub/static/frontend/Codazon/fastest_bigmarket/en_AU/fonts/opensans/semibold/opensans-600.woff2) format("woff2"),*/
/*    url(https://staging.securemobile.net/pub/static/frontend/Codazon/fastest_bigmarket/en_AU/fonts/opensans/semibold/opensans-600.woff) format("woff");*/
/*    font-weight: 600;*/
/*    font-style: normal;*/
/*    font-display: auto;*/
/*}*/
/*@font-face {*/
/*    font-family: Poppins;*/
/*    src: url(https://staging.securemobile.net/pub/static/frontend/Codazon/fastest_bigmarket/en_AU/fonts/opensans/bold/opensans-700.woff2) format("woff2"),*/
/*    url(https://staging.securemobile.net/pub/static/frontend/Codazon/fastest_bigmarket/en_AU/fonts/opensans/bold/opensans-700.woff) format("woff");*/
/*    font-weight: 700;*/
/*    font-style: normal;*/
/*    font-display: auto;*/
/*}*/
/*@font-face {*/
/*    font-family: luma-icons;*/
/*    src: url(https://staging.securemobile.net/pub/static/frontend/Codazon/fastest_bigmarket/en_AU/fonts/Luma-Icons.woff2) format("woff2"),*/
/*    url(https://staging.securemobile.net/pub/static/frontend/Codazon/fastest_bigmarket/en_AU/fonts/Luma-Icons.woff) format("woff");*/
/*    font-weight: 400;*/
/*    font-style: normal;*/
/*    font-display: auto;*/
/*}*/

#new-sm-menu {
  display: block !important;
  position: fixed;
  background: white;
  z-index: 1010;
  top: 0;
  left: -100%;
  -webkit-transition: left 0.3s;
  -moz-transition: left 0.3s;
  -ms-transition: left 0.3s;
  -o-transition: left 0.3s;
  transition: left 0.3s;
  width: 100%;
  height: 100%;
  overflow: scroll;
}

.sm-nav-open #new-sm-menu {
  left: 0;
}

#new-sm-menu-close,
#new-sm-menu-close:visited {
  position: absolute;
  right: 0;
  font-size: 24px;
  padding-top: 3px;
  padding-bottom: 3px;
  width: 40px;
  text-align: center;
  color: black !important;
  text-decoration: none;
  cursor: pointer;
}

#new-sm-menu-close:hover,
#new-sm-menu-close:active {
  color: white !important;
  background: black !important;
}

#new-sm-menu .menu-nav {
  width: calc(50% - 20px);
  float: left;
  text-align: center;
  font-size: 18px;
  padding-top: 7px;
  padding-bottom: 7px;
  cursor: pointer;

  background: none;
  color: black;
}

#new-sm-menu .menu-nav.sm-nav-active {
  background: #0296d5;
  color: white;
}

.categories-header {
  text-align: center;
  font-size: 18px;
  margin-top: 10px;
}

#categories-level-2 {
  display: none;
}

#categories-level-3 {
  display: none;
}

#new-sm-menu #account-nav {
  display: none;
}

.new-sm-nav-item {
  font-size: 18px;
  margin: 15px;
  position: relative;
  /*color: black;*/
}

#new-sm-menu a,
#new-sm-menu a:visited {
  text-decoration: none;
  cursor: pointer;
  color: black;
}

#new-sm-menu a:hover,
#new-sm-menu a:active {
  color: #0296d5;
}

.new-sm-nav-item-caret:after {
  position: absolute;
  right: 0;
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f105";
  font-size: 24px;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  -moz-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  width: 40px;
  height: 40px;
  line-height: 40px;
  top: -9px;
}

.category-back-icon {
  font-size: 32px !important;
  position: absolute;
  top: 4px;
  left: 16px;
  cursor: pointer;
}

#new-sm-menu-buffer {
  width: 100%;
  height: 40px;
}

.nav-open .page-wrapper {
  left: 0 !important;
}

.nav-open .nav-sections {
  left: -100% !important;
}

#new-sm-menu-nav-parent a:hover,
#new-sm-menu-nav-parent a:active {
  /*color: black !important;*/
}

#new-sm-menu-nav-parent .sm-nav-active {
  cursor: default;
}

#new-sm-menu-mask {
  display: none;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1009;
}

.restrict-scrolling {
  overflow: hidden;
}

@media (min-width: 768px) {
  #new-sm-menu {
    width: 400px;
    height: 600px;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transition: left 1s;
    -moz-transition: left 1s;
    -ms-transition: left 1s;
    -o-transition: left 1s;
    transition: left 1s;
  }

  .sm-nav-open #new-sm-menu {
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

/*! CSS Used from: https://mobimall.com/pub/static/version1610436409/frontend/Codazon/fastest_bigmarket/en_AU/MobileDigital_Click2Buy/all.min.css ; media=all */
@media all {
  body * {
    font-family: "Montserrat", sans-serif !important;
  }
  .checkout-bold {
    font-size: 16px;
    font-weight: 800;
    margin-bottom: 0;
  }
  .checkout-bold-left {
    font-size: 16px;
    font-weight: 800;
    margin-bottom: 0;
  }
  .checkout-image {
    width: 134px;
    float: right;
    margin-bottom: 0;
  }
  .checkout-normal {
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 0;
  }
  .checkout-qty {
    text-transform: uppercase;
    margin-top: 2px;
    margin-bottom: 6px;
  }
  .checkout-qty-number {
    border: 1px solid #000;
    padding: 2px 7px;
    border-radius: 8px;
    font-size: 15px;
    font-weight: 700;
  }
  .checkout-delivery-total {
    float: left;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
  }
  .checkout-delivery-total-right {
    float: right;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
  }
  .checkout-row-clear {
    clear: both;
  }
  .checkout-line-break {
    width: 100%;
    clear: both;
    margin-top: 5px;
  }
  #checkout-cart-products {
    max-height: 400px;
    overflow: scroll;
  }
  .checkout-left-col {
    float: left;
    width: 55%;
  }
  .checkout-right-col {
    float: left;
    width: 45%;
    position: relative;
  }
  #minicart-content-wrapper {
    display: none;
    color: #000;
    position: relative;
  }
  #cart-popup-checkout {
    background: #0198d8;
    border: none;
    border-radius: 30px;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    float: left;
    width: 40%;
    color: #fff;
  }
  #cart-popup-checkout:hover {
    color: #fff;
  }
  #cart-popup-checkout:active {
    color: #fff;
  }
  #cart-popup-keep-shopping {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    background: 0 0;
    border: 1px solid #000;
    border-radius: 45px;
    color: #000;
    float: right;
    width: 58%;
  }
  #cart-popup-keep-shopping:hover {
    color: #000;
  }
  #cart-popup-keep-shopping:active {
    color: #000;
  }
  #minicart-content-wrapper .checkout-line-break {
    background: 0 0;
  }
  #cart-popup-close-window-parent {
    clear: both;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  #cart-popup-close-window {
    text-align: center;
    font-size: 11px;
    font-weight: 400;
    text-transform: uppercase;
    text-decoration: underline;
  }
  #cart-popup-buttons {
    width: 100%;
    height: 40px;
  }
  #checkout-delivery-parent {
    margin-top: 10px;
  }
  .minicart-wrapper .block-minicart {
    margin: 10px;
    margin-top: 20px;
    z-index: 100 !important;
  }
  #cart-popup-x {
    font-size: 23px;
    font-weight: 400;
    position: absolute;
    top: -20px;
    right: -12px;
  }
  #cart-popup-heading {
    text-align: center;
    font-size: 20px;
    font-weight: 300;
  }
  .minicart-wrapper .block-minicart a:active {
    color: #000 !important;
  }
  .minicart-wrapper .block-minicart a:hover {
    color: #000 !important;
  }
  .minicart-wrapper .block-minicart a:visited {
    color: #000 !important;
  }
  .minicart-wrapper .block-minicart a {
    color: #000 !important;
  }
  .remove-product {
    border-radius: 39px;
    padding: 6px 10px;
    background: #0198d8;
    border: none;
    font-size: 11px;
    color: #fff;
    margin-left: 8px;
  }
  .remove-product:hover,
  .remove-product:active,
  .remove-product:visited {
    background: #0198d8;
    border: none;
    color: #fff;
  }
  .minicart-wrapper .ui-dialog .block-minicart {
    padding: 20px 20px 0;
    right: 0;
    width: 340px;
    position: absolute;
    top: 100%;
    z-index: 5;
    color: #999;
    background-color: #fff;
  }
  @media only screen and (max-width: 767px) {
    .minicart-wrapper .ui-dialog .block-minicart {
      right: 0;
      left: 0;
      width: auto;
      min-width: 300px;
      top: 100%;
    }
  }
  #checkout-cart-products > a:not(:last-child) .checkout-right-col {
    margin-bottom: 10px;
  }
}
/*! CSS Used from: https://mobimall.com/pub/static/version1610436409/frontend/Codazon/fastest_bigmarket/en_AU/css/styles-m.min.css ; media=all */
@media all {
  img {
    border: 0;
  }
  img {
    max-height: 100%;
    max-width: 100%;
  }
  p {
    margin-top: 0;
    margin-bottom: 1rem;
  }
  a {
    color: #1979c3;
    text-decoration: none;
  }
  a:visited {
    color: #1979c3;
    text-decoration: none;
  }
  a:hover {
    color: #006bb4;
    text-decoration: underline;
  }
  a:active {
    color: #ff5501;
    text-decoration: underline;
  }
  button {
    background-image: none;
    background: #006588;
    border: 1px solid #006588;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: "Poppins", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 700;
    margin: 0;
    padding: 7px 15px;
    font-size: 1.4rem;
    line-height: 1.6rem;
    box-sizing: border-box;
    vertical-align: middle;
  }
  button:focus,
  button:active {
    background: #fff;
    border: 1px solid #006588;
    color: #006588;
  }
  button:hover {
    background: #fff;
    border: 1px solid #006588;
    color: #006588;
  }
  button::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
  *:focus {
    box-shadow: none;
    outline: 0;
  }
  a {
    color: #282828;
  }
  a:hover {
    color: #006588;
  }
  .header a {
    color: #fff;
  }
  .header a:hover {
    color: #006588;
    text-decoration: none;
  }
  button {
    border-radius: 0;
    margin-bottom: 5px;
  }
  body:not(._keyfocus) button:focus {
    box-shadow: none;
  }
  button {
    text-transform: uppercase;
    -moz-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    -moz-backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  button {
    color: #fff;
    background-color: #006588;
    border-color: #006588;
    font: 500 14px/1.35 Poppins, Helvetica Neue, Verdana, Arial, sans-serif;
  }
  button:hover {
    color: #006588;
    background-color: #fff;
    border-color: #006588;
  }
  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  *:before,
  *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .minicart-wrapper .ui-dialog .block-minicart {
    padding: 20px 20px 0;
    right: 0;
    width: 340px;
    position: absolute;
    top: 100%;
    z-index: 5;
    box-shadow: 0 1px 3px #e1e1e1;
    -moz-box-shadow: 0 1px 3px #e1e1e1;
    -webkit-box-shadow: 0 1px 3px #e1e1e1;
    -o-box-shadow: 0 1px 3px #e1e1e1;
    -ms-box-shadow: 0 1px 3px #e1e1e1;
    color: #999;
    background-color: #fff;
  }
  .minicart-wrapper .block-minicart {
    margin-bottom: 0;
  }
  .minicart-wrapper .block-minicart a {
    color: #999;
    text-decoration: none;
  }
  .minicart-wrapper .block-minicart a:visited {
    color: #999;
    text-decoration: none;
  }
  .minicart-wrapper .block-minicart a:hover {
    color: #006588;
    text-decoration: none;
  }
  .minicart-wrapper .block-minicart a:active {
    color: #006588;
    text-decoration: none;
  }
  .minicart-wrapper .block-minicart:after {
    left: auto;
    right: 25px;
  }
  .minicart-wrapper .block-minicart:before {
    left: auto;
    right: 26px;
  }
  @media only screen and (max-width: 767px) {
    .minicart-wrapper .ui-dialog .block-minicart {
      right: 0;
      left: 0;
      width: auto;
      min-width: 300px;
      top: 100%;
    }
  }
}
/*! CSS Used from: https://mobimall.com/pub/static/version1610436409/frontend/Codazon/fastest_bigmarket/en_AU/css/styles-l.min.css ; media=screen and (min-width: 768px) */
@media screen and (min-width: 768px) {
  a {
    color: #282828;
  }
  a:hover {
    color: #006588;
  }
  .header a {
    color: #fff;
  }
  .header a:hover {
    color: #006588;
    text-decoration: none;
  }
  button {
    text-transform: uppercase;
    -moz-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    -moz-backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  button {
    color: #fff;
    background-color: #006588;
    border-color: #006588;
    font: 500 14px/1.35 Poppins, Helvetica Neue, Verdana, Arial, sans-serif;
  }
  button:hover {
    color: #006588;
    background-color: #fff;
    border-color: #006588;
  }
  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  *:before,
  *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  @media all and (min-width: 768px), print {
    .minicart-wrapper .block-minicart {
      right: 0;
    }
  }
}
/*! CSS Used from: https://mobimall.com/pub/static/version1610436409/frontend/Codazon/fastest_bigmarket/en_AU/css/print.min.css ; media=print */
@media print {
  @media print {
    * {
      -webkit-filter: none !important;
      background: 0 0 !important;
      color: #000 !important;
      filter: none !important;
      text-shadow: none !important;
    }
    a,
    a:visited {
      text-decoration: underline !important;
    }
    img {
      page-break-inside: avoid;
    }
    img {
      max-width: 100% !important;
    }
    p {
      orphans: 3;
      widows: 3;
    }
  }
}
/*! CSS Used fontfaces */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUOjIg1_i6t8kCHKm459WxZqh7p29NfpiOjk20.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUOjIg1_i6t8kCHKm459WxZqh7g29NfpiOjk20.woff2)
    format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUOjIg1_i6t8kCHKm459WxZqh7r29NfpiOjk20.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUOjIg1_i6t8kCHKm459WxZqh7q29NfpiOjk20.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUOjIg1_i6t8kCHKm459WxZqh7k29NfpiOj.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUPjIg1_i6t8kCHKm459WxZBg_z8fZwjimrq1Q_.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUPjIg1_i6t8kCHKm459WxZBg_z-PZwjimrq1Q_.woff2)
    format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUPjIg1_i6t8kCHKm459WxZBg_z8_Zwjimrq1Q_.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUPjIg1_i6t8kCHKm459WxZBg_z8vZwjimrq1Q_.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUPjIg1_i6t8kCHKm459WxZBg_z_PZwjimrqw.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUPjIg1_i6t8kCHKm459WxZYgzz8fZwjimrq1Q_.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUPjIg1_i6t8kCHKm459WxZYgzz-PZwjimrq1Q_.woff2)
    format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUPjIg1_i6t8kCHKm459WxZYgzz8_Zwjimrq1Q_.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUPjIg1_i6t8kCHKm459WxZYgzz8vZwjimrq1Q_.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUPjIg1_i6t8kCHKm459WxZYgzz_PZwjimrqw.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUQjIg1_i6t8kCHKm459WxRxC7m0dR9pBOi.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUQjIg1_i6t8kCHKm459WxRzS7m0dR9pBOi.woff2)
    format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUQjIg1_i6t8kCHKm459WxRxi7m0dR9pBOi.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUQjIg1_i6t8kCHKm459WxRxy7m0dR9pBOi.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUQjIg1_i6t8kCHKm459WxRyS7m0dR9pA.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUPjIg1_i6t8kCHKm459WxZOg3z8fZwjimrq1Q_.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUPjIg1_i6t8kCHKm459WxZOg3z-PZwjimrq1Q_.woff2)
    format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUPjIg1_i6t8kCHKm459WxZOg3z8_Zwjimrq1Q_.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUPjIg1_i6t8kCHKm459WxZOg3z8vZwjimrq1Q_.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUPjIg1_i6t8kCHKm459WxZOg3z_PZwjimrqw.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUQjIg1_i6t8kCHKm45_QpRxC7m0dR9pBOi.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUQjIg1_i6t8kCHKm45_QpRzS7m0dR9pBOi.woff2)
    format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUQjIg1_i6t8kCHKm45_QpRxi7m0dR9pBOi.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUQjIg1_i6t8kCHKm45_QpRxy7m0dR9pBOi.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUQjIg1_i6t8kCHKm45_QpRyS7m0dR9pA.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_aZA3gTD_vx3rCubqg.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_aZA3g3D_vx3rCubqg.woff2)
    format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_aZA3gbD_vx3rCubqg.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_aZA3gfD_vx3rCubqg.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_aZA3gnD_vx3rCs.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_cJD3gTD_vx3rCubqg.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_cJD3g3D_vx3rCubqg.woff2)
    format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_cJD3gbD_vx3rCubqg.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_cJD3gfD_vx3rCubqg.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_cJD3gnD_vx3rCs.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUSjIg1_i6t8kCHKm459WRhyyTh89ZNpQ.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUSjIg1_i6t8kCHKm459W1hyyTh89ZNpQ.woff2)
    format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUSjIg1_i6t8kCHKm459WZhyyTh89ZNpQ.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUSjIg1_i6t8kCHKm459WdhyyTh89ZNpQ.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_ZpC3gTD_vx3rCubqg.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_ZpC3g3D_vx3rCubqg.woff2)
    format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_ZpC3gbD_vx3rCubqg.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_ZpC3gfD_vx3rCubqg.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_ZpC3gnD_vx3rCs.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Poppins";
  src: url("https://mobimall.com/pub/static/version1610436409/frontend/Codazon/fastest_bigmarket/en_AU/fonts/opensans/light/opensans-300.woff2")
      format("woff2"),
    url("https://mobimall.com/pub/static/version1610436409/frontend/Codazon/fastest_bigmarket/en_AU/fonts/opensans/light/opensans-300.woff")
      format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: "Poppins";
  src: url("https://mobimall.com/pub/static/version1610436409/frontend/Codazon/fastest_bigmarket/en_AU/fonts/opensans/regular/opensans-400.woff2")
      format("woff2"),
    url("https://mobimall.com/pub/static/version1610436409/frontend/Codazon/fastest_bigmarket/en_AU/fonts/opensans/regular/opensans-400.woff")
      format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: "Poppins";
  src: url("https://mobimall.com/pub/static/version1610436409/frontend/Codazon/fastest_bigmarket/en_AU/fonts/opensans/semibold/opensans-600.woff2")
      format("woff2"),
    url("https://mobimall.com/pub/static/version1610436409/frontend/Codazon/fastest_bigmarket/en_AU/fonts/opensans/semibold/opensans-600.woff")
      format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: "Poppins";
  src: url("https://mobimall.com/pub/static/version1610436409/frontend/Codazon/fastest_bigmarket/en_AU/fonts/opensans/bold/opensans-700.woff2")
      format("woff2"),
    url("https://mobimall.com/pub/static/version1610436409/frontend/Codazon/fastest_bigmarket/en_AU/fonts/opensans/bold/opensans-700.woff")
      format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: "Poppins";
  src: url("https://mobimall.com/pub/static/version1610436409/frontend/Codazon/fastest_bigmarket/en_AU/fonts/opensans/light/opensans-300.woff2")
      format("woff2"),
    url("https://mobimall.com/pub/static/version1610436409/frontend/Codazon/fastest_bigmarket/en_AU/fonts/opensans/light/opensans-300.woff")
      format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: "Poppins";
  src: url("https://mobimall.com/pub/static/version1610436409/frontend/Codazon/fastest_bigmarket/en_AU/fonts/opensans/regular/opensans-400.woff2")
      format("woff2"),
    url("https://mobimall.com/pub/static/version1610436409/frontend/Codazon/fastest_bigmarket/en_AU/fonts/opensans/regular/opensans-400.woff")
      format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: "Poppins";
  src: url("https://mobimall.com/pub/static/version1610436409/frontend/Codazon/fastest_bigmarket/en_AU/fonts/opensans/semibold/opensans-600.woff2")
      format("woff2"),
    url("https://mobimall.com/pub/static/version1610436409/frontend/Codazon/fastest_bigmarket/en_AU/fonts/opensans/semibold/opensans-600.woff")
      format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: "Poppins";
  src: url("https://mobimall.com/pub/static/version1610436409/frontend/Codazon/fastest_bigmarket/en_AU/fonts/opensans/bold/opensans-700.woff2")
      format("woff2"),
    url("https://mobimall.com/pub/static/version1610436409/frontend/Codazon/fastest_bigmarket/en_AU/fonts/opensans/bold/opensans-700.woff")
      format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: auto;
}

#hamburger-nav-menu-x {
  display: none;
  z-index: 1000;
  color: black;
  position: fixed;
  top: 20px;
  right: 20px;
  font-size: 25px;
  cursor: pointer;
}

.push-toright nav#nav-main {
  top: 0 !important;
}

.push-toright #hamburger-nav-menu-x {
  display: block;
}
